import React from 'react';
import styles from './navigationButton.module.scss';
import cn from "classnames";
import { useSelector } from 'react-redux';

interface NavigationButtonProps {
  icon: string;
  label: string,
  customClass?: string;
  onClick?: (e: React.MouseEvent) => void;
}

const NavigationButton = ({ icon, label, customClass, onClick }: NavigationButtonProps) => {
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  return (
    <button
      onClick={onClick}
      className={cn(styles.navigationButton, customClass)}
      style={{ ...(primaryColor && { background: primaryColor }) }}
    >
      <div className={cn(styles.navigationButtonIcon, 'navigation-button__icon')}>
        <img src={icon} />
      </div>
      <span className={cn(styles.navigationButtonLabel, 'navigation-button__label')}>{label}</span>
    </button>
  );
};

export default NavigationButton;
