import React from 'react';
import * as Api from '../../api';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import styles from './styles.module.scss';

interface CloverProps {
  disabled?: boolean;
  terminalNumber?: number | string;
}

const Clover = ({ disabled, terminalNumber }: CloverProps) => {
  const [cloverPass, setCloverPass] = React.useState('');

  const pairingClover = async () => {
    try {
      const { data } = await Api.pairingCloverDevice(terminalNumber);
      setCloverPass(data);
      // setLoading(false);
    } catch (error) {
      setCloverPass('');
      // setLoading(false);
    }
  };

  const closeCloverPassModal = () => {
    setCloverPass('');
  };

  return (
    <>
      <Button disabled={disabled} handleClick={pairingClover} theme="transparent" customClass={styles.cloverButton}>
        Pair Clover
      </Button>
      <Modal isOpen={!!cloverPass}>
        <div className={styles.cloverPassModal}>
          <p>Your Clover pairing code is</p>
          <div className={styles.cloverPass}>{cloverPass}</div>
          <p>
            Please enter this code into
            <br />
            the Clover pairing terminal
            <br />
            to complete the pairing process.
          </p>
          <div className={styles.cloverPassButtonWrap}>
            <Button handleClick={closeCloverPassModal} theme="red" customClass={styles.cloverPassButton}>
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Clover;
