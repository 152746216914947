import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Api from '../../api';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import { saveCustomerToStoreAction } from '../../store/Customer/actions';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import styles from './styles.module.scss';
import isAdult from '../../utils/isAdult';
import Button from '../../components/Button';
import { addSignatureOfNewGuestAction } from '../../store/Customer/actions';
import Checkbox from '../../components/Checkbox';
import { terminalModeStateSelector } from '../../store/Settings/selectors';
import SignatureCanvas from '../../components/SignatureCanvas';
import WarningAlert from '../../components/WarningAlert';
import WaiverAgreementText from '../../components/WaiverAgreementText';
import { addGuestInEventAction } from '../../store/Customer/actions/eventsActions';

const WaiverAgreementAdultScreen = () => {
  // @ts-ignore
  const { id } = useParams();
  const dispatch = useDispatch();
  const sigPad = React.useRef(null);

  const {
    customerStore,
    newCustomerStore,
    newGuestStore,
    registrationSettings: { adultAge, waiverStep, allowDuplicateEmail },
    terminalSettings: { enableESignature },
    confirmationCodeReducer,
    terminalSettings: { checkInType },
    events,
  } = useSelector(state => state);
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  const terminalMode = useSelector(terminalModeStateSelector);
  const [currentAdultNewGuest, setCurrentAdultNewGuest] = useState(null);
  const [agreeWithRules, setAgreeWithRules] = useState(false);
  const [agreeWithRulesError, setAgreeWithRulesError] = useState(false);
  const [agreeWithESignitureRules, setAgreeWithESignitureRules] = useState(false);
  const [agreeWithESignitureRulesError, setAgreeWithESignitureRulesError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentAdultNewGuest(newGuestStore.guests.find(obj => obj.id == id || obj.custId == id));
  }, [id]);

  const startRegistrationFlow = async listOfNewGuests => {
    try {
      setLoading(true);
      const listOfNewMinorGuests = listOfNewGuests.filter(
        item => !isAdult(item.commonData.birthDate, adultAge) && !item.custId,
      );
      const listOfNewMinorGuestsWithId = listOfNewGuests.filter(
        item => !isAdult(item.commonData.birthDate, adultAge) && item.custId,
      );
      const listOfNewAdultGuests = listOfNewGuests.filter(
        item => isAdult(item.commonData.birthDate, adultAge) && !item.custId,
      );
      const listOfNewAdultGuestsWithId = listOfNewGuests.filter(
        item => isAdult(item.commonData.birthDate, adultAge) && item.custId,
      );
      const { data: primaryCust } = await Api.createCustomer(newCustomerStore.commonData);
      await Api.createAdultWaiver({
        signature: newCustomerStore.signature,
        adultCustId: primaryCust.custId,
      });
      if (!primaryCust.doNotMail && primaryCust.emailAddress) {
        await Api.sendWelcomeEmail(primaryCust.custId);
      }
      for (const item of listOfNewMinorGuests) {
        const dataOfNewMinor = {
          ...item.commonData,
          address: primaryCust.address,
          address2: primaryCust.address2,
          city: primaryCust.city,
          state: primaryCust.state,
          zip: primaryCust.zip,
          country: primaryCust.country,
          sourceId: primaryCust.sourceId,
          phoneNumber: primaryCust.cell,
        };
        if (allowDuplicateEmail) {
          dataOfNewMinor['emailAddress'] = primaryCust.emailAddress;
        }
        const {
          data: { custId: curentCustId },
        } = await Api.createCustomer(dataOfNewMinor);
        await Api.createMinorWaiver({
          guardianSignature: newCustomerStore.signature,
          minorCustId: curentCustId,
          guardianCustId: primaryCust.custId,
        });
        await Api.addToCustomerGroup({
          custIds: [curentCustId],
          createdById: primaryCust.custId,
        });
        await Api.addToCustomerRelationships({
          relationships: [
            {
              relationship: item.relationships,
              custId: curentCustId,
            },
          ],
          custId: primaryCust.custId,
        });
      }

      for (const item of listOfNewMinorGuestsWithId) {
        await Api.createMinorWaiver({
          guardianSignature: newCustomerStore.signature,
          minorCustId: item.custId,
          guardianCustId: primaryCust.custId,
        });
        await Api.addToCustomerGroup({
          custIds: [item.custId],
          createdById: primaryCust.custId,
        });
        await Api.addToCustomerRelationships({
          relationships: [
            {
              relationship: item.relationships,
              custId: item.custId,
            },
          ],
          custId: primaryCust.custId,
        });
      }

      for (const item of listOfNewAdultGuestsWithId) {
        await Api.addToCustomerGroup({
          custIds: [item.custId],
          createdById: primaryCust.custId,
        });
        await Api.addToCustomerRelationships({
          relationships: [
            {
              relationship: item.relationships,
              custId: item.custId,
            },
          ],
          custId: primaryCust.custId,
        });
      }

      for (const item of listOfNewAdultGuests) {
        const {
          data: { custId: curentCustId, doNotMail, emailAddress },
        } = await Api.createCustomer(item.commonData);
        await Api.createAdultWaiver({
          signature: item.signature,
          adultCustId: curentCustId,
        });
        if (!doNotMail && emailAddress) {
          await Api.sendWelcomeEmail(curentCustId);
        }
        await Api.addToCustomerGroup({
          custIds: [curentCustId],
          createdById: primaryCust.custId,
        });
        await Api.addToCustomerRelationships({
          relationships: [
            {
              relationship: item.relationships,
              custId: curentCustId,
            },
          ],
          custId: primaryCust.custId,
        });
      }

      for (const item of listOfNewGuests) {
        if (item.edited) {
          await Api.updateCustomer(item.custId, item.commonData);
          if (item.customerImage) {
            await Api.uploadCustomerPicture(item.custId, item.customerImage);
          }
        }
      }

      const { data: primaryCustomer } = await Api.fetchCustomerById(primaryCust.custId);
      dispatch(
        saveCustomerToStoreAction({
          ...primaryCustomer,
        }),
      );

      if (confirmationCodeReducer?.isEventList || events?.selectedEvent || confirmationCodeReducer?.data?.eventId) {
        addGuestToEvent(primaryCust.custId);
      } else if (confirmationCodeReducer?.success && checkInType?.onlineBooking) {
        history.replace(ROUTES.ASSIGN_ACTIVITIES);
      } else {
        history.replace(ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw new TypeError(e.message);
    }
  };

  const onBackClick = () => {
    history.goBack();
  };

  const addGuestToEvent = async (custId: number) => {
    try {
      if (events.selectedEvent && custId) {
        dispatch(
          addGuestInEventAction({
            eventId: events.selectedEvent.eventID,
            custId: String(custId),
          }),
        );
      }

      if (confirmationCodeReducer?.success) {
        dispatch(
          addGuestInEventAction({
            eventId: confirmationCodeReducer?.data?.eventId,
            custId: String(custId),
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onContinueClick = async () => {
    try {
      const listOfNewGuests = newGuestStore.guests;
      let indexOfCurrentNewGuest = listOfNewGuests.findIndex(item => item.id == id);
      if (indexOfCurrentNewGuest === -1) {
        indexOfCurrentNewGuest = listOfNewGuests.findIndex(item => item.custId == id);
      }

      if (!agreeWithRules) {
        setAgreeWithRulesError(true);
        return;
      }
      setAgreeWithRulesError(false);
      if (enableESignature && !agreeWithESignitureRules) {
        setAgreeWithESignitureRulesError(true);
        return;
      }

      if (!agreeWithESignitureRules) {
        if (sigPad?.current?.isEmpty()) {
          return;
        }
      }

      const signatureOfCustomer = sigPad.current.toDataURL().substr(22);

      listOfNewGuests[indexOfCurrentNewGuest].signature = signatureOfCustomer;
      const listOfNewAdultGuestsWithoutSignature = listOfNewGuests.filter(
        item => isAdult(item.commonData.birthDate, adultAge) && !item.signature && !item.custId,
      );

      if (listOfNewAdultGuestsWithoutSignature.length > 0) {
        dispatch(addSignatureOfNewGuestAction(listOfNewGuests));
        sigPad.current.clear();
        setAgreeWithRules(false);
        history.push(`${ROUTES.WAIVER_AGREEMENT_ADULT}/${listOfNewAdultGuestsWithoutSignature[0].id}`);
        return;
      }
      await startRegistrationFlow(listOfNewGuests);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Fragment>
      <ContentContainer loading={loading}>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />
        <div className={styles.content}>
          <div className={styles.waiverAgreementTextContainer}>
            <span className={styles.waiverAgreementTextTitle}>
              Waiver Agreement - {currentAdultNewGuest ? currentAdultNewGuest.commonData.fName : ''}{' '}
              {currentAdultNewGuest ? currentAdultNewGuest.commonData.lName : ''}
            </span>
            <WaiverAgreementText waiverText={waiverStep.adultWaiverText} />
          </div>

          <div className={styles.waiverAgreementTextContainer}>
            <div className={styles.iAgreeTextbox}>
              <Checkbox
                state={!!agreeWithRules}
                label="I agree to the terms and conditions of the above agreement."
                handleClick={() => {
                  agreeWithRules && setAgreeWithESignitureRules(false);
                  setAgreeWithRules(prevState => !prevState);
                  setAgreeWithRulesError(false);
                }}
                error={agreeWithRulesError}
              />
            </div>
          </div>
          {enableESignature && (
            <div className={styles.waiverAgreementTextContainer}>
              <div className={styles.iAgreeTextbox}>
                <Checkbox
                  state={agreeWithESignitureRules}
                  label="By checking this checkbox, I agree that the e-signature below will be the electronic representation of my signature for all purposes, including legally binding contracts - just the same as a pen-and-paper signature."
                  handleClick={() => {
                    setAgreeWithESignitureRules(prevState => !prevState);
                    setAgreeWithESignitureRulesError(false);
                  }}
                  disabled={!agreeWithRules}
                  error={agreeWithESignitureRulesError}
                />
              </div>
            </div>
          )}
          {agreeWithRules && (
            <div className={styles.waiverAgreementTextContainer}>
              <div className={styles.waiverAgreementSignatureCanvas}>
                <span className={styles.waiverAgreementSignatureTitle}>
                  <h2>
                    {agreeWithESignitureRules ? (
                      'Your E-signature'
                    ) : (
                      <>
                        {currentAdultNewGuest ? currentAdultNewGuest.commonData.fName : ''}{' '}
                        {currentAdultNewGuest ? currentAdultNewGuest.commonData.lName : ''}, please sign below.
                      </>
                    )}
                  </h2>
                </span>
              </div>
              <div className={styles.waiverAgreementSignatureCanvas}>
                <div className={styles.waiverAgreementSignatureCanvasInner}>
                  <SignatureCanvas
                    canvasProps={{ width: 500, height: 200 }}
                    ref={sigPad}
                    sigPad={sigPad}
                    signature={`${currentAdultNewGuest.commonData.fName} ${currentAdultNewGuest.commonData.lName}`}
                    enableDigitalSignature={agreeWithESignitureRules}
                    readOnlySignature={enableESignature}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.navigation}>
          <Button theme="white" size="base" handleClick={onBackClick} customClass={styles.whiteButton}>
            Back
          </Button>
          <div className={styles.navigationFilter} />
          <Button theme="red" size="base" handleClick={onContinueClick} customClass={styles.redButton}>
            Continue
          </Button>
        </div>
      </ContentContainer>
      <WarningAlert
        isOpen={!events.eventExists && !!events.errorMessage}
        title=""
        description={events.errorMessage}
        handleClick={() => history.replace(ROUTES.ROOT)}
      />
      <IdleMonitor />
    </Fragment>
  );
};

export default WaiverAgreementAdultScreen;
