import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import Modal from '../Modal';
import Button from '../Button';
import TextFieldWithLabel from '../TextFieldWithLabel';
import Keyboard from '../Keyboard/KeyboardHOC';
import styles from './styles.module.scss';
import { startPaymentAction } from '../../store/Payment/actions';
import { cancelUpdateKdsDataStartAction } from '../../store/PayByLink/actions';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

const KDSform = ({ isOpen, setIsOpen }: { isOpen?: boolean; setIsOpen?: any }) => {
  const dispatch = useDispatch();
  const { terminalSettings, payByLink } = useSelector(state => state);
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);

  const [fieldsOfForm, setFieldsOfForm] = React.useState<any>({
    foodPhoneNumber: '',
    foodOrderName: '',
  });

  const onChangeInputValue = (e: any) => {
    setFieldsOfForm({ ...fieldsOfForm, [activeField]: e.target.value });
  };

  const onKeyboardPress = (newFieldValue: string, fieldName) => {
    setFieldsOfForm({ ...fieldsOfForm, [fieldName]: newFieldValue });
  };

  const [activeField, setActiveField] = React.useState('foodOrderName');

  const startCheckout = () => {
    if (fieldsOfForm['foodPhoneNumber'] && fieldsOfForm['foodOrderName']) {
      dispatch(startPaymentAction({ kdsData: fieldsOfForm }));
    }
  };

  const cancelPurchaseFlow = () => {
    dispatch(cancelUpdateKdsDataStartAction());
    setIsOpen(false);
  };

  return (
    <Modal isOpen={payByLink.inProcess || isOpen} closeButtonHandler={cancelPurchaseFlow}>
      <div className={cn(styles.container, { [styles.containerSmall]: terminalSettings.nativeKeyboard })}>
        <div className={styles.content}>
          <h2 className={styles.title}>What's your name & number</h2>
          <h3 className={styles.subTitle}>We’ll text you your receipt and updates on your order.</h3>
          <div className={styles.form}>
            <div className={styles.formItem}>
              <TextFieldWithLabel
                type="text"
                readOnly={!nativeKeyboardState}
                value={fieldsOfForm.foodOrderName}
                placeholder=""
                onChange={onChangeInputValue}
                onFocus={() => setActiveField('foodOrderName')}
                label="Your name"
                required
              />
            </div>
            <div className={styles.formItem}>
              <TextFieldWithLabel
                type="text"
                readOnly={!nativeKeyboardState}
                value={fieldsOfForm.foodPhoneNumber}
                placeholder=""
                onChange={onChangeInputValue}
                onFocus={() => setActiveField('foodPhoneNumber')}
                label="Mobile number"
                required
              />
            </div>
          </div>
        </div>
        {terminalSettings.nativeKeyboard && (
          <div className={styles.navigation}>
            <Button
              handleClick={cancelPurchaseFlow}
              customClass={cn(styles.navigationButton, styles.navigationButtonCancel)}
              theme=""
            >
              Cancel
            </Button>
            <Button handleClick={startCheckout} customClass={cn(styles.navigationButton)} theme="red">
              Continue
            </Button>
          </div>
        )}

        {!terminalSettings.nativeKeyboard && (
          <div className={styles.keyboard}>
            <Keyboard
              fieldName={activeField}
              valueOfField={fieldsOfForm[activeField]}
              onChange={onKeyboardPress}
              hideKeyboard={() => {}}
              handleEnter={() => {}}
            />
            <div className={styles.navigation}>
              <Button
                handleClick={cancelPurchaseFlow}
                customClass={cn(styles.navigationButton, styles.navigationButtonCancel)}
                theme=""
              >
                Cancel
              </Button>
              <Button handleClick={startCheckout} customClass={cn(styles.navigationButton)} theme="red">
                Continue
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default KDSform;
