import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { history } from '../../App';

import { resetPaymentProcessAction } from '../../store/Payment/actions';

import Button from '../../components/Button';
import HeaderPanel from '../../components/HeaderPanel';
import NavigationPanel from '../../components/NavigationPanel';
import ContentContainer from '../../components/ContentContainer';
import { ReactComponent as PayByCardIcon } from '../../assets/icons/payByCard.svg';
import { ReactComponent as Pay2TextIcon } from '../../assets/icons/pay2text.svg';

import styles from './styles.module.scss';
import { ROUTES } from '../../constants';
import IdleMonitor from '../../services/IdleMonitor';
import classNames from 'classnames';
import Icon from 'components/Icon';

const PaymentOnlineScreen = () => {
  const dispatch = useDispatch();

  const {
    terminalSettings,
    cartStore: { checkSubtotal, checkTax, checkClubSpeedKioskFeeTotal, checkTotal },
    payByLink: { loading },
  } = useSelector(state => state);

  const { backgroundImgType, backgroundImg } = useSelector(state => state.kioskSettings.branding);

  const text2PayHandler = () => {
    history.push(ROUTES.TEXT_2_PAY);
  };

  const payWithCardHandler = () => {
    history.push(ROUTES.PAYMENT_CARD_CONNECT);
  };

  const goBack = () => {
    dispatch(resetPaymentProcessAction());
  };

  return (
    <Fragment>
      <ContentContainer loading={loading} theme="red" customImg={backgroundImg} backgroundImgType={backgroundImgType}>
        <HeaderPanel theme="dark" />
        <div className={styles.contentContainer}>
          <div className={classNames(backgroundImg && styles.contentContainerForCustomImage)}>
            <div className={styles.subtotal}>
              <span>
                Subtotal: <span className={styles.value}>${checkSubtotal.toFixed(2)}</span>
              </span>
            </div>
            <div className={styles.tax}>
              <span>
                {terminalSettings.isConvenienceFeeEnabled ? 'Taxes & Fees:' : 'Tax:'}
                <span className={styles.value}>${(checkClubSpeedKioskFeeTotal + checkTax).toFixed(2)}</span>
              </span>
            </div>
            <div className={styles.balance}>
              <span>
                Balance: <span className={styles.value}>${(checkTotal + checkClubSpeedKioskFeeTotal).toFixed(2)}</span>
              </span>
            </div>
          </div>
          <>
            <Button handleClick={payWithCardHandler} theme="white" customClass={cn(styles.payWithCardBtn)}>
              <Icon ImgComponent={PayByCardIcon} />
              Pay with card
            </Button>
            {!!terminalSettings.text2pay && (
              <Button handleClick={text2PayHandler} theme="white" customClass={cn(styles.text2PayBtn)}>
                <Icon ImgComponent={Pay2TextIcon} />
                Pay on phone
              </Button>
            )}
          </>
        </div>

        <NavigationPanel onLeftButtonClick={goBack} leftButtonTitle="Back" />
      </ContentContainer>
      <IdleMonitor />
    </Fragment>
  );
};

export default PaymentOnlineScreen;
