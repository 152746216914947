import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import Icon from 'components/Icon';
import { useSelector } from 'react-redux';

interface CircleButtonProps {
  //   children: React.ReactNode;
  onClick?: () => void;
  text: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  color: string;
  buttonWrapperClass?: string;
  buttonIconClass?: string;
  buttontextClass?: string;
}
const IconWithText: React.FC<CircleButtonProps> = ({
  onClick,
  text,
  icon,
  color,
  buttonWrapperClass,
  buttonIconClass,
  buttontextClass,
}: CircleButtonProps) => {
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  return (
    <div className={cn(styles.circleButtonInner, buttonWrapperClass)} onClick={onClick}>
      <div>
        <button className={cn(styles.addGuestButton, buttonIconClass)}>
          <Icon ImgComponent={icon} />
        </button>
        <h1
          style={{
            color: primaryColor ? primaryColor : '',
          }}
        >
          {text}
        </h1>
      </div>
    </div>
  );
};

export default IconWithText;
