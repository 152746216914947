import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import { updateNewGuestAction } from '../../store/Customer/actions';
import ContentContainer from '../../components/ContentContainer';
import Button from '../../components/Button';
import IdleMonitor from '../../services/IdleMonitor';
import HeaderPanel from '../../components/HeaderPanel';
import MemberCard from '../../components/MemberCard';
import styles from './styles.module.scss';
import isAdult from '../../utils/isAdult';
import Confirm from '../../components/Prompt';
import IconWithText from '../../components/IconWithText';
import { ReactComponent as AddNewButton } from '../../assets/icons/add-new-button.svg';

const ReviewOfNewCustomersScreen = () => {
  const dispatch = useDispatch();
  const {
    newCustomerStore,
    newGuestStore,
    registrationSettings: { adultAge },
  } = useSelector(state => state);
  const [guestIdForRemoving, setGuestIdForRemoving] = useState(null);
  const [heightOfContainer, setHeightOfContainer] = useState<any>(0);
  const [confirmGuestRemoving, setConfirmGuestRemoving] = useState(false);

  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  const editGuest = guest => {
    if (!!guest.custId) {
      history.replace(`${ROUTES.EDIT_NEW_GUEST}/cust/${guest.custId}`);
      return;
    }
    if (!!guest.id) {
      history.replace(`${ROUTES.EDIT_NEW_GUEST}/local/${guest.id}`);
      return;
    }
    history.replace(`${ROUTES.EDIT_NEW_GUEST}/local/primary`);
  };
  useEffect(() => {
    handleResizeContainer();
    window.addEventListener('resize', handleResizeContainer);
    return () => {
      window.removeEventListener('resize', handleResizeContainer);
    };
  }, []);
  const handleResizeContainer = () => {
    const windowHeight = window.innerHeight;
    const headerPanelHeight = document.getElementById('headerPanel').offsetHeight;
    const titlePanelHeight = document.getElementById('titlePanel').offsetHeight;
    setHeightOfContainer(windowHeight - (headerPanelHeight + titlePanelHeight));
  };

  const startRemovingGuest = () => {
    setConfirmGuestRemoving(true);
  };

  const cancelGuestRemoving = () => {
    setGuestIdForRemoving(null);
    setConfirmGuestRemoving(false);
  };

  const removeGuest = () => {
    const newGuestList = [...newGuestStore.guests];
    const guest = newGuestList.find(item => item.custId == guestIdForRemoving || item.id == guestIdForRemoving);
    if (guest.custId) {
      newGuestList.splice(
        newGuestList.findIndex(item => item.custId === guestIdForRemoving),
        1,
      );
      dispatch(updateNewGuestAction(newGuestList));
      return;
    }
    newGuestList.splice(
      newGuestList.findIndex(item => item.id === guestIdForRemoving),
      1,
    );
    dispatch(updateNewGuestAction(newGuestList));
    setConfirmGuestRemoving(false);
  };

  const onAddGuestClick = () => {
    history.push(ROUTES.NEW_GUEST);
  };

  const onContinueClick = () => {
    if (
      [newCustomerStore, ...newGuestStore.guests].filter((item: any) => !isAdult(item.commonData.birthDate, adultAge))
    ) {
      history.push(ROUTES.WAIVER_AGREEMENT_MINOR);
      return;
    }
    history.push(ROUTES.WAIVER_AGREEMENT_ADULT);
    return;
  };

  return (
    <Fragment>
      <ContentContainer>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />

        <div id="titlePanel" className={styles.titleWrap}>
          <h2 className={styles.title}>Review</h2>
        </div>
        <div className={styles.container} style={{ height: `${heightOfContainer}px` }}>
          <div className={styles.smth}>
            <h2 className={styles.title}>Tap card to edit</h2>
            <div className={styles.cardTitleWrapper}>
              <IconWithText
                icon={AddNewButton}
                text="ADD MORE GUESTS"
                color="red"
                onClick={onAddGuestClick}
                buttonIconClass={styles.addGuestButton}
                buttontextClass={styles.iconButtonText}
              />
            </div>
          </div>
          <div className={styles.membersListContainer}>
            {/*<div className={styles.membersListContainerInner}>*/}
            {[newCustomerStore, ...newGuestStore.guests].map((item, index) => (
              <MemberCard
                customerImage={item.commonData.customerImage}
                key={index}
                isPrimaryMember={!!item.custId || !!item.id ? false : true}
                handleClick={() => editGuest(item)}
                removeHandler={() => startRemovingGuest()}
                custId={item.commonData.custId}
                firstName={item.commonData.fName}
                lastName={item.commonData.lName}
                birthday={item.commonData.birthDate}
                gender={item.commonData.gender}
              />
            ))}
            {/*</div>*/}
          </div>
        </div>
        <div className={styles.navigation}>
          <div className={styles.navigationFilter} />
          <Button theme="red" size="base" handleClick={onContinueClick} customClass={styles.redButton}>
            Continue
          </Button>
        </div>
      </ContentContainer>
      <Confirm
        isOpen={confirmGuestRemoving}
        title="Are you sure?"
        titleButtonCancel="Cancel"
        titleButtonOk="Continue"
        handleReturnClick={() => cancelGuestRemoving()}
        handleContinueClick={() => removeGuest()}
      >
        <div className={styles.confirmRemoveFromGroup}>
          <p>
            Are you sure want to remove this
            <br />
            person from your group?
          </p>
        </div>
      </Confirm>
      <IdleMonitor />
    </Fragment>
  );
};

export default ReviewOfNewCustomersScreen;
