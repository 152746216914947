import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import * as Api from '../../api';
import { COUNTRIES, GENDER, RELATIONSHIP_TYPES } from '../../constants';
import { getCustomerGroupAction } from '../../store/CustomerGroup/actions';
import moment from 'moment';
import detectLabelField from '../../utils/detectLabelField';
import detectRequiredField from '../../utils/detectRequiredField';
import isAdult from '../../utils/isAdult';
import validator from '../../utils/validator';

import HeaderPanel from '../HeaderPanel';
import WizardFormStep from '../WizardFormStep';
import WarningAlert from '../WarningAlert';
import Button from '../Button';
import Checkbox from '../Checkbox';
import styles from './styles.module.scss';
import ContentContainer from '../ContentContainer';
import SignatureCanvas from '../SignatureCanvas';
import WaiverAgreementText from '../WaiverAgreementText/WaiverAgreementText';
import STATES from '../../constants/states';
import { findLastVisitedGuest } from '../../utils/core';
import Confirm from '../../components/Prompt';

interface Props {
  endAddingGuestProcessHandler?: () => void;
  handleHomeClick?: () => void;
}

type formType = {
  [key: string]: any;
};

const AddGuestForm = ({ endAddingGuestProcessHandler, handleHomeClick }: Props) => {
  const dispatch = useDispatch();
  const {
    customerStore: primaryCustomer,
    customerGroup,
    registrationSettings: {
      adultRegistrationFields,
      minorRegistrationFields,
      newAdultRegistrationSteps,
      newMinorRegistrationSteps,
      adultAge,
      waiverStep,
      allowDuplicateEmail,
      defaultCountry,
      enableRelationshipScreen,
    },
    source: sourceStore,
    terminalSettings: { enableESignature },
  } = useSelector(state => state);

  const [isMemberExists, setIsMemberExists] = useState(false);

  const sigPad = React.useRef(null);

  const [registrationSteps, setRegistrationSteps] = useState(newAdultRegistrationSteps);
  const [registrationFields, setRegistrationFields] = useState(adultRegistrationFields);

  const [activeField, setActiveField] = useState(registrationSteps[0]);
  const [fieldsOfForm, setFieldsOfForm] = useState<formType>({});
  const [doNotMail, setDoNotMail] = useState(true);
  const [customerRelationships, setCustomerRelationships] = useState('');
  const [agreeWithRules, setAgreeWithRules] = useState(false);
  const [agreeWithRulesError, setAgreeWithRulesError] = useState(false);
  const [agreeWithESignitureRules, setAgreeWithESignitureRules] = useState(false);
  const [agreeWithESignitureRulesError, setAgreeWithESignitureRulesError] = useState(false);
  const [errorsOfField, setErrorsOfField] = useState('');
  const [emailAddressIsUnavailable, setEmailAddressIsUnavailable] = useState(false);
  const [customersFound, setCustomersFound] = useState([]);
  const [continueButtonClicked, setContinueButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (defaultCountry) {
      setFieldsOfForm({ ...fieldsOfForm, country: defaultCountry });
    }
  }, []);

  const onChangeField = async (fieldName: string, newFieldValue: string) => {
    try {
      setFieldsOfForm({ ...fieldsOfForm, [fieldName]: newFieldValue });
      setErrorsOfField('');
      if (fieldName === 'fName' || fieldName === 'lName') {
        await validator(
          activeField,
          newFieldValue,
          detectRequiredField(registrationFields, activeField),
          allowDuplicateEmail,
        );
      }
    } catch (e) {
      setErrorsOfField(e.message);
    }
  };

  const onSelectHandler = async (fieldName: string, newFieldValue: string) => {
    if (fieldsOfForm[fieldName] === newFieldValue) {
      await onChangeField(fieldName, '');
    } else {
      await onChangeField(fieldName, newFieldValue);
    }
  };

  const onChangeRelationships = (fieldName: string, newFieldValue: string) => {
    if (customerRelationships === newFieldValue) {
      setCustomerRelationships('');
    } else {
      setCustomerRelationships(newFieldValue);
    }
  };

  const findCustomer = async () => {
    const { data: responseData } = await Api.findCustomerByNameAndDOB(
      fieldsOfForm.fName,
      fieldsOfForm.lName,
      fieldsOfForm.birthDate,
    );
    responseData.birthDate = moment(responseData.birthDate).format('MM/DD/YYYY');
    return responseData;
  };

  const updateDoNotMail = () => {
    setDoNotMail(prevState => !prevState);
  };

  const validateIfPersonExists = () => {
    const findIndex = [primaryCustomer, ...customerGroup.members].findIndex(
      el =>
        el.lName === fieldsOfForm?.lName &&
        el.fName === fieldsOfForm?.fName &&
        el.birthDate === fieldsOfForm?.birthDate,
    );

    return findIndex !== -1 ? false : true;
  };

  const goToNextStep = async () => {
    try {
      if (activeField === 'lName') {
        setLoading(true);
        const showErroMessage = validateIfPersonExists();
        if (showErroMessage) {
          setIsMemberExists(true);
          setLoading(false);
          return;
        }
        setLoading(false);
      }

      if (activeField === 'customerImage') {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 3000));
        setLoading(false);
      }
      await validator(
        activeField,
        fieldsOfForm[activeField],
        detectRequiredField(registrationFields, activeField),
        allowDuplicateEmail,
      );

      if (_.last(registrationSteps) === activeField) {
        // @ts-ignore
        return enableRelationshipScreen ? setActiveField('relationships') : goToAfterRelationships();
      }
      if (activeField === 'birthDate') {
        if (!fieldsOfForm.birthDate) {
          return;
        }
        // @ts-ignore
        if (!isAdult(fieldsOfForm.birthDate, adultAge)) {
          setRegistrationSteps(newMinorRegistrationSteps);
          setRegistrationFields(minorRegistrationFields);
        } else {
          setRegistrationSteps(newAdultRegistrationSteps);
          setRegistrationFields(adultRegistrationFields);
        }
      }

      if (activeField === 'lName') {
        const response = await findCustomer();
        if (response.length === 1) {
          setCustomersFound(response);
          return enableRelationshipScreen ? setActiveField('relationships') : goToAfterRelationships();
        } else if (response.length > 1) {
          const lastVisitedGuest = findLastVisitedGuest(response);
          setCustomersFound([lastVisitedGuest]);
          return enableRelationshipScreen ? setActiveField('relationships') : goToAfterRelationships();
        }
      }

      const indexOfCurrentField = registrationSteps.indexOf(activeField);
      return setActiveField(registrationSteps[indexOfCurrentField + 1]);
    } catch (e) {
      setErrorsOfField(e.message);
    }
  };

  const goToAfterRelationships = async () => {
    try {
      if (enableRelationshipScreen) {
        await validator(activeField, customerRelationships, true, allowDuplicateEmail);
      }

      if (customersFound.length === 1) {
        if (isAdult(customersFound[0].birthDate, adultAge)) {
          await Api.addToCustomerGroup({
            custIds: [customersFound[0].custId],
            createdById: primaryCustomer.custId,
          });
          await Api.addToCustomerRelationships({
            relationships: [
              {
                relationship: customerRelationships,
                custId: customersFound[0].custId,
              },
            ],
            custId: primaryCustomer.custId,
          });
          dispatch(getCustomerGroupAction({ custId: primaryCustomer.custId }));
          return endAddingGuestProcessHandler();
        }
        if (!isAdult(customersFound[0].birthDate, adultAge)) {
          setActiveField('waiverAgreementMinor');
        }
      }
      const waiverAgreementStep = isAdult(fieldsOfForm.birthDate, adultAge)
        ? 'waiverAgreementAdult'
        : 'waiverAgreementMinor';
      return setActiveField(waiverAgreementStep);
    } catch (e) {
      setErrorsOfField(e.message);
    }
  };

  const goToPrevStep = () => {
    setErrorsOfField('');
    if (activeField === 'relationships') {
      if (customersFound.length === 1) {
        setActiveField('lName');
        return;
      }
      setActiveField(_.last(registrationSteps));
      return;
    }
    if (activeField === 'waiverAgreementMinor' || activeField === 'waiverAgreementAdult') {
      enableRelationshipScreen ? setActiveField('relationships') : goToAfterRelationships();
      return;
    }
    const indexOfCurrentField = registrationSteps.indexOf(activeField);
    if (indexOfCurrentField <= 0) return endAddingGuestProcessHandler();
    setActiveField(registrationSteps[indexOfCurrentField - 1]);
  };

  const finishAddGuest = async () => {
    if (continueButtonClicked === true) {
      return;
    }
    setContinueButtonClicked(true);
    let newCustomerData = {
      ...fieldsOfForm,
    };

    if (!agreeWithRules) {
      setAgreeWithRulesError(true);
      setContinueButtonClicked(false);
      return;
    }
    setAgreeWithRulesError(false);
    if (enableESignature && !agreeWithESignitureRules) {
      setAgreeWithESignitureRulesError(true);
      setContinueButtonClicked(false);
      return;
    }

    if (!agreeWithESignitureRules) {
      if (sigPad?.current?.isEmpty()) {
        setContinueButtonClicked(false);
        setContinueButtonClicked(false);
        return;
      }
    }

    setLoading(true);
    const signatureOfCustomer = sigPad.current.toDataURL().substr(22);

    if (customersFound.length === 1) {
      await Api.addToCustomerGroup({
        custIds: [customersFound[0].custId],
        createdById: primaryCustomer.custId,
      });
      await Api.addToCustomerRelationships({
        relationships: [
          {
            relationship: customerRelationships,
            custId: customersFound[0].custId,
          },
        ],
        custId: primaryCustomer.custId,
      });
      await Api.createMinorWaiver({
        guardianSignature: signatureOfCustomer,
        minorCustId: customersFound[0].custId,
        guardianCustId: primaryCustomer.custId,
      });
    }
    if (customersFound.length === 0 || customersFound.length > 1) {
      if (!isAdult(fieldsOfForm.birthDate, adultAge)) {
        newCustomerData = {
          ...newCustomerData,
          address: primaryCustomer.address,
          address2: primaryCustomer.address2,
          city: primaryCustomer.city,
          state: primaryCustomer.state,
          zip: primaryCustomer.zip,
          country: primaryCustomer.country,
          sourceId: primaryCustomer.sourceId,
          phoneNumber: primaryCustomer.cell,
        };
        if (allowDuplicateEmail) {
          newCustomerData['emailAddress'] = primaryCustomer.emailAddress;
        }
      }
      const { data } = await Api.createCustomer({ ...newCustomerData, doNotMail });
      if (!data.doNotMail && data.emailAddress) {
        await Api.sendWelcomeEmail(data.custId);
      }
      await Api.addToCustomerGroup({
        custIds: [data.custId],
        createdById: primaryCustomer.custId,
      });
      await Api.addToCustomerRelationships({
        relationships: [
          {
            relationship: customerRelationships,
            custId: data.custId,
          },
        ],
        custId: primaryCustomer.custId,
      });
      if (isAdult(data.birthDate, adultAge)) {
        await Api.createAdultWaiver({
          signature: signatureOfCustomer,
          adultCustId: data.custId,
        });
      } else {
        await Api.createMinorWaiver({
          guardianSignature: signatureOfCustomer,
          minorCustId: data.custId,
          guardianCustId: primaryCustomer.custId,
        });
      }
    }
    dispatch(getCustomerGroupAction({ custId: primaryCustomer.custId }));
    setLoading(false);
    endAddingGuestProcessHandler();
  };

  return (
    <Fragment>
      <ContentContainer loading={loading} bgColor="#FFFFFF">
        <div className={styles.addGuestForm}>
          <div className={styles.container}>
            <HeaderPanel handleHomeClick={handleHomeClick} homeButton logOutButton border />
            <div className={styles.content}>
              <WizardFormStep
                type="numeric"
                fieldName="birthDate"
                value={fieldsOfForm['birthDate'] || ''}
                label={detectLabelField(registrationFields, 'birthDate') + ' MM/DD/YYYY'}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'birthDate' === activeField}
                required={detectRequiredField(registrationFields, 'birthDate')}
                goToNextStep={goToNextStep}
                goToPrevStep={goToPrevStep}
              />
              <WizardFormStep
                type="text"
                fieldName="fName"
                value={fieldsOfForm['fName']}
                label={detectLabelField(registrationFields, 'fName')}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'fName' === activeField}
                required={detectRequiredField(registrationFields, 'fName')}
                goToNextStep={goToNextStep}
                goToPrevStep={goToPrevStep}
              />
              <WizardFormStep
                type="text"
                fieldName="lName"
                value={fieldsOfForm['lName']}
                label={detectLabelField(registrationFields, 'lName')}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'lName' === activeField}
                required={detectRequiredField(registrationFields, 'lName')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="email"
                fieldName="emailAddress"
                value={fieldsOfForm['emailAddress']}
                label={detectLabelField(adultRegistrationFields, 'emailAddress')}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'emailAddress' === activeField}
                required={detectRequiredField(adultRegistrationFields, 'emailAddress')}
                doNotMail={!doNotMail}
                setDoNotMail={updateDoNotMail}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="text"
                fieldName="cell"
                value={fieldsOfForm['cell']}
                label={detectLabelField(registrationFields, 'cell')}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'cell' === activeField}
                required={detectRequiredField(registrationFields, 'cell')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="radio"
                fieldName="gender"
                value={fieldsOfForm['gender']}
                label={detectLabelField(registrationFields, 'gender')}
                options={GENDER}
                valueField="value"
                displayField="title"
                onChange={onSelectHandler}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'gender' === activeField}
                required={detectRequiredField(registrationFields, 'gender')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="text"
                fieldName="racerName"
                value={fieldsOfForm['racerName']}
                label={detectLabelField(registrationFields, 'racerName')}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'racerName' === activeField}
                required={detectRequiredField(registrationFields, 'racerName')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="text"
                fieldName="address"
                value={fieldsOfForm['address']}
                label={detectLabelField(registrationFields, 'address')}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'address' === activeField}
                required={detectRequiredField(registrationFields, 'address')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="text"
                fieldName="address2"
                value={fieldsOfForm['address2']}
                label={detectLabelField(registrationFields, 'address2')}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'address2' === activeField}
                required={detectRequiredField(registrationFields, 'address2')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="text"
                fieldName="city"
                value={fieldsOfForm['city']}
                label={detectLabelField(registrationFields, 'city')}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'city' === activeField}
                required={detectRequiredField(registrationFields, 'city')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type={fieldsOfForm['country'] === 'United States' ? 'typeSelector' : 'text'}
                fieldName="state"
                value={fieldsOfForm['state']}
                options={STATES}
                displayField={detectLabelField(adultRegistrationFields, 'state')}
                label={detectLabelField(adultRegistrationFields, 'state')}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'state' === activeField}
                required={detectRequiredField(adultRegistrationFields, 'state')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="typeSelector"
                fieldName="country"
                value={fieldsOfForm['country']}
                options={COUNTRIES}
                displayField={detectLabelField(adultRegistrationFields, 'country')}
                label={detectLabelField(adultRegistrationFields, 'country')}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'country' === activeField}
                required={detectRequiredField(adultRegistrationFields, 'country')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
                keyboardLayout={'country'}
              />
              <WizardFormStep
                type="text"
                fieldName="zip"
                value={fieldsOfForm['zip']}
                label={detectLabelField(registrationFields, 'zip')}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'zip' === activeField}
                required={detectRequiredField(registrationFields, 'zip')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="itemSelect"
                fieldName="sourceId"
                value={fieldsOfForm['sourceId']}
                label={detectLabelField(registrationFields, 'sourceId')}
                options={sourceStore.data}
                valueField="sourceId"
                displayField="sourceName"
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'sourceId' === activeField}
                required={detectRequiredField(registrationFields, 'sourceId')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="picture"
                fieldName="customerImage"
                value={fieldsOfForm['customerImage']}
                label="Picture"
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'customerImage' === activeField}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="text"
                fieldName="licenseNumber"
                value={fieldsOfForm['licenseNumber']}
                label={detectLabelField(adultRegistrationFields, 'licenseNumber')}
                onChange={onChangeField}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'licenseNumber' === activeField}
                required={detectRequiredField(adultRegistrationFields, 'licenseNumber')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="checkbox"
                fieldName="custom1"
                value={fieldsOfForm['custom1']}
                label={detectLabelField(registrationFields, 'custom1')}
                onChange={onChangeField}
                errorMessage={errorsOfField}
                isActive={'custom1' === activeField}
                required={detectRequiredField(registrationFields, 'custom1')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="checkbox"
                fieldName="custom2"
                value={fieldsOfForm['custom2']}
                label={detectLabelField(registrationFields, 'custom2')}
                onChange={onChangeField}
                errorMessage={errorsOfField}
                isActive={'custom2' === activeField}
                required={detectRequiredField(registrationFields, 'custom2')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="checkbox"
                fieldName="custom3"
                value={fieldsOfForm['custom3']}
                label={detectLabelField(registrationFields, 'custom3')}
                onChange={onChangeField}
                errorMessage={errorsOfField}
                isActive={'custom3' === activeField}
                required={detectRequiredField(registrationFields, 'custom3')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="checkbox"
                fieldName="custom4"
                value={fieldsOfForm['custom4']}
                label={detectLabelField(registrationFields, 'custom4')}
                onChange={onChangeField}
                errorMessage={errorsOfField}
                isActive={'custom4' === activeField}
                required={detectRequiredField(registrationFields, 'custom4')}
                goToNextStep={() => goToNextStep()}
                goToPrevStep={() => goToPrevStep()}
              />
              <WizardFormStep
                type="itemSelect"
                fieldName="relationships"
                value={customerRelationships}
                options={RELATIONSHIP_TYPES}
                valueField="value"
                displayField="label"
                label={`What is ${fieldsOfForm?.fName}'s relationship to ${primaryCustomer.fName}`}
                onChange={onChangeRelationships}
                placeholder={''}
                errorMessage={errorsOfField}
                isActive={'relationships' === activeField}
                required={true}
                goToNextStep={() => goToAfterRelationships()}
                goToPrevStep={() => goToPrevStep()}
              />

              {'waiverAgreementMinor' === activeField && (
                <Fragment>
                  <div className={styles.contentWaiver}>
                    <div className={styles.waiverAgreementTextContainer}>
                      <span className={styles.waiverAgreementTextTitle}>
                        {customersFound.length === 1 && (
                          <span className={styles.waiverAgreementTextTitle}>
                            Waiver Agreement - {primaryCustomer.fName} {primaryCustomer.lName} (signing for{' '}
                            {customersFound[0].fName} {customersFound[0].lName})
                          </span>
                        )}
                        {(!customersFound.length || customersFound.length > 1) && (
                          <span className={styles.waiverAgreementTextTitle}>
                            Waiver Agreement - {primaryCustomer.fName} {primaryCustomer.lName} (signing for{' '}
                            {fieldsOfForm['fName']} {fieldsOfForm['lName']})
                          </span>
                        )}
                      </span>
                      <WaiverAgreementText waiverText={waiverStep.minorWaiverText} />
                    </div>
                    <div className={styles.waiverAgreementTextContainer}>
                      <div className={styles.iAgreeTextbox}>
                        <Checkbox
                          state={!!agreeWithRules}
                          label={`I, ${primaryCustomer.fName} ${primaryCustomer.lName}, agree to the terms and conditions of the above agreement and acknowledge that the signature below will apply to both my own waiver, and the waiver for any minors present in my group.`}
                          handleClick={() => {
                            agreeWithRules && setAgreeWithESignitureRules(false);
                            setAgreeWithRules(prevState => !prevState);
                            setAgreeWithRulesError(false);
                          }}
                          error={agreeWithRulesError}
                        />
                      </div>
                    </div>
                    {enableESignature && (
                      <div className={styles.waiverAgreementTextContainer}>
                        <div className={styles.iAgreeTextbox}>
                          <Checkbox
                            state={agreeWithESignitureRules}
                            label="By checking this checkbox, I agree that the e-signature below will be the electronic representation of my signature for all purposes, including legally binding contracts - just the same as a pen-and-paper signature."
                            handleClick={() => {
                              setAgreeWithESignitureRules(prevState => !prevState);
                              setAgreeWithESignitureRulesError(false);
                            }}
                            disabled={!agreeWithRules}
                            error={agreeWithESignitureRulesError}
                          />
                        </div>
                      </div>
                    )}
                    {agreeWithRules && (
                      <div className={styles.waiverAgreementTextContainer}>
                        <div className={styles.waiverAgreementSignatureCanvas}>
                          <span className={styles.waiverAgreementSignatureTitle}>
                            <h2>
                              {agreeWithESignitureRules ? (
                                'Your E-signature'
                              ) : (
                                <>
                                  {primaryCustomer.fName} {primaryCustomer.lName}, please sign below.
                                </>
                              )}
                            </h2>
                          </span>
                        </div>
                        <div className={styles.waiverAgreementSignatureCanvas}>
                          <div className={styles.waiverAgreementSignatureCanvasInner}>
                            <SignatureCanvas
                              canvasProps={{ width: 500, height: 200 }}
                              ref={sigPad}
                              sigPad={sigPad}
                              signature={`${primaryCustomer.fName} ${primaryCustomer.lName}`}
                              enableDigitalSignature={agreeWithESignitureRules}
                              readOnlySignature={enableESignature}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={styles.navigation}>
                      <Button theme="white" handleClick={() => goToPrevStep()} customClass={styles.navigationButton}>
                        Back
                      </Button>
                      <div className={styles.navigationFilter} />
                      <Button theme="red" handleClick={() => finishAddGuest()} customClass={styles.navigationButton}>
                        Continue
                      </Button>
                    </div>
                  </div>
                </Fragment>
              )}
              {'waiverAgreementAdult' === activeField && (
                <Fragment>
                  <div className={styles.contentWaiver}>
                    <div className={styles.waiverAgreementTextContainer}>
                      <span className={styles.waiverAgreementTextTitle}>
                        Waiver Agreement - {fieldsOfForm['fName']} {fieldsOfForm['lName']}
                      </span>
                      <WaiverAgreementText waiverText={waiverStep.adultWaiverText} />
                    </div>
                    <div className={styles.waiverAgreementTextContainer}>
                      <div className={styles.iAgreeTextbox}>
                        <Checkbox
                          state={!!agreeWithRules}
                          label={`I, ${fieldsOfForm['fName']} ${fieldsOfForm['lName']}, agree to the terms and conditions of the above agreement and acknowledge that the signature below will apply to both my own waiver, and the waiver for any minors present in my group.`}
                          handleClick={() => {
                            agreeWithRules && setAgreeWithESignitureRules(false);
                            setAgreeWithRules(prevState => !prevState);
                            setAgreeWithRulesError(false);
                          }}
                          error={agreeWithRulesError}
                        />
                      </div>
                    </div>
                    {enableESignature && (
                      <div className={styles.waiverAgreementTextContainer}>
                        <div className={styles.iAgreeTextbox}>
                          <Checkbox
                            state={agreeWithESignitureRules}
                            label="By checking this checkbox, I agree that the e-signature below will be the electronic representation of my signature for all purposes, including legally binding contracts - just the same as a pen-and-paper signature."
                            handleClick={() => {
                              setAgreeWithESignitureRules(prevState => !prevState);
                              setAgreeWithESignitureRulesError(false);
                            }}
                            disabled={!agreeWithRules}
                            error={agreeWithESignitureRulesError}
                          />
                        </div>
                      </div>
                    )}
                    {agreeWithRules && (
                      <div className={styles.waiverAgreementTextContainer}>
                        <div className={styles.waiverAgreementSignatureCanvas}>
                          <span className={styles.waiverAgreementSignatureTitle}>
                            <h2>
                              {agreeWithESignitureRules ? (
                                'Your E-signature'
                              ) : (
                                <>
                                  {fieldsOfForm['fName']} {fieldsOfForm['lName']}, please sign below.
                                </>
                              )}
                            </h2>
                          </span>
                        </div>
                        <div className={styles.waiverAgreementSignatureCanvas}>
                          <div className={styles.waiverAgreementSignatureCanvasInner}>
                            <SignatureCanvas
                              canvasProps={{ width: 500, height: 200 }}
                              ref={sigPad}
                              sigPad={sigPad}
                              signature={`${fieldsOfForm['fName']} ${fieldsOfForm['lName']}`}
                              enableDigitalSignature={agreeWithESignitureRules}
                              readOnlySignature={enableESignature}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className={styles.navigation}>
                      <Button theme="white" handleClick={() => goToPrevStep()} customClass={styles.navigationButton}>
                        Back
                      </Button>
                      <div className={styles.navigationFilter} />
                      <Button theme="red" handleClick={() => finishAddGuest()} customClass={styles.navigationButton}>
                        Continue
                      </Button>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </ContentContainer>
      <WarningAlert
        isOpen={emailAddressIsUnavailable}
        title="Whoops!"
        description="This email address is taken.\n Please use another email address or if you believe you’ve reached this message by mistake,\n log out and see a cashier for help."
        handleClick={() => setEmailAddressIsUnavailable(false)}
      />
      <WarningAlert
        isOpen={isMemberExists}
        title="Oops!"
        description="You already have a member of your group that matches the information provided."
        handleClick={() => setIsMemberExists(false)}
      />
    </Fragment>
  );
};

export default AddGuestForm;
