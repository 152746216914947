import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectModeAction } from '../../store/Settings/actions';
import HeaderPanel from '../../components/HeaderPanel';

import modeFoodIcon from '../../assets/icons/mode_food.svg';
import modeFoodAndActivitiesIcon from '../../assets/icons/mode_tickets_and_food.svg';

import styles from './styles.module.scss';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../../components/ContentContainer';

const ModeSelectionScreen = () => {
  const dispatch = useDispatch();
  const welcomeText = useSelector(state => state.kioskSettings.branding.welcomeText);

  const selectMode = (mode: string) => {
      dispatch(selectModeAction(mode));
    };

  return (
    <>
      <ContentContainer theme="red">
        <div className={styles.content}>
          <div className={styles.greeting}>
            <h1 className={styles.title}>Welcome</h1>
            <h2 className={styles.userName}>{welcomeText ? welcomeText : 'What are you here for?'}</h2>
          </div>
          <div className={styles.line} />
          <div className={styles.navigation}>
            <button className={styles.navigationItem} onClick={() => selectMode('only_food')}>
              <img src={modeFoodIcon} className={styles.navigationItemIcon} alt="" />
              <span className={styles.navigationItemTitle}>I want food</span>
            </button>
            <button className={styles.navigationItem} onClick={() => selectMode('food_&_activities')}>
              <img src={modeFoodAndActivitiesIcon} className={styles.navigationItemIcon} alt="" />
              <span className={styles.navigationItemTitle}>I want fun</span>
            </button>
          </div>
        </div>
      </ContentContainer>
      <IdleMonitor />
    </>
  );
};

export default ModeSelectionScreen;
