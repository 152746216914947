import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';

interface IProps {
  ImgComponent: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  text: string;
}

const BackgroundIcon = ({ ImgComponent, text }: IProps) => {
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  return (
    <div className={styles.wrapperDiv}>
      <ImgComponent
        style={{
          fill: primaryColor || '#df151c',
        }}
      />
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default BackgroundIcon;
