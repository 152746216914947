import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../App';
import { sha256 } from '../../utils/bcrypt';
import IdleMonitor from '../../services/IdleMonitor';
import { updateCommonDataOfNewCustomerAction } from '../../store/Customer/actions';
import Button from '../../components/Button';
import styles from './styles.module.scss';
import PhoneKeypad from '../../components/PhoneKeypad';
import { ROUTES } from '../../constants';
import TextFieldWithLabel from '../../components/TextFieldWithLabel';
import cn from 'classnames';
import HeaderPanel from '../../components/HeaderPanel';
import ContentContainer from '../../components/ContentContainer';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

type formType = {
  [key: string]: string;
};

const SignUpFirstStepScreen = () => {
  const dispatch = useDispatch();
  const { newCustomerStore } = useSelector(state => state);
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);
  const [activeField, setActiveField] = useState('pin');
  const [fieldsOfForm, setFieldsOfForm] = useState<formType>({
    pin: '',
    confirmPin: '',
    confirmationMode: 'false',
  });

  const [errorsOfForm, setErrorsOfForm] = useState({
    pin: '',
    confirmPin: '',
  });

  const validatePin = () => {
    if (fieldsOfForm.pin === '') {
      setErrorsOfForm({ ...errorsOfForm, pin: 'Please enter a 4 digit pin' });
      return false;
    }
    if (fieldsOfForm.pin.length !== 4) {
      setErrorsOfForm({ ...errorsOfForm, pin: 'Please enter a 4 digit pin' });
      return false;
    }
    return true;
  };
  const validateConfirmPin = () => {
    if (fieldsOfForm.confirmPin === '') {
      setErrorsOfForm({ ...errorsOfForm, pin: 'Pins did not match. Try again' });
      return false;
    }
    if (fieldsOfForm.pin !== fieldsOfForm.confirmPin) {
      setErrorsOfForm({ ...errorsOfForm, pin: 'Pins did not match. Try again' });
      return false;
    }
    return true;
  };

  const goBack = () => {
    history.goBack();
  };

  const phoneKeypadHandler = (newValueOfField: string) => {
    if (newValueOfField.length <= 4) {
      setFieldsOfForm({ ...fieldsOfForm, [activeField]: newValueOfField });
    }
  };
  const phoneKeypadHandler2 = (newValueOfField: string) => {
    if (newValueOfField.length <= 4) {
      setFieldsOfForm({ ...fieldsOfForm, ['confirmPin']: newValueOfField });
    }
  };

  const goToConfirmPin = async () => {
    if (!validatePin()) return;
    setActiveField('confirmPin');
    setFieldsOfForm({ ...fieldsOfForm, ['confirmationMode']: 'true' });
    return;
  };
  const goToSignUp = async () => {
    if (!validateConfirmPin()) {
      setActiveField('pin');
      fieldsOfForm.pin = '';
      fieldsOfForm.confirmPin = '';
      errorsOfForm.pin = 'Pins did not match. Please try again';
      setFieldsOfForm({ ...fieldsOfForm, ['confirmationMode']: 'false' });
      return;
    }
    const pinInHash = await sha256(fieldsOfForm.pin);
    dispatch(updateCommonDataOfNewCustomerAction({ pin: pinInHash }));
    history.push(ROUTES.SIGN_UP);
  };

  return (
    <Fragment>
      <ContentContainer>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />

        <div className={styles.wizardFormField}>
          <div className={styles.wizardFormFieldInner}>
            <div className={styles.rowMargin}>
              {fieldsOfForm.confirmationMode === 'false' && (
                <TextFieldWithLabel
                  type="password"
                  value={fieldsOfForm.pin}
                  placeholder=""
                  readOnly={!nativeKeyboardState}
                  required={true}
                  label={`No profile found for ${newCustomerStore.commonData.cell}, please create a 4 digit pin`}
                  invalid={!!errorsOfForm.pin}
                  errorMessage={errorsOfForm.pin}
                  onFocus={() => setActiveField('pin')}
                />
              )}

              {fieldsOfForm.confirmationMode === 'true' && (
                <TextFieldWithLabel
                  type="password"
                  value={fieldsOfForm.confirmPin}
                  placeholder=""
                  readOnly={!nativeKeyboardState}
                  required={true}
                  label="Confirm 4 Digit Pin"
                  invalid={!!errorsOfForm.confirmPin}
                  errorMessage={errorsOfForm.confirmPin}
                  onFocus={() => setActiveField('confirmPin')}
                />
              )}
            </div>
            <div className={styles.rowMargin}>
              {fieldsOfForm.confirmationMode === 'false' && (
                <PhoneKeypad valueOfField={fieldsOfForm[activeField]} onChange={phoneKeypadHandler} />
              )}
              {fieldsOfForm.confirmationMode === 'true' && (
                <PhoneKeypad valueOfField={fieldsOfForm[activeField]} onChange={phoneKeypadHandler2} />
              )}
            </div>

            <div className={styles.navigation}>
              <Button
                theme={'white'}
                handleClick={goBack}
                customClass={cn(styles.navigationButton, styles.navigationButtonBack)}
              >
                Back
              </Button>
              <div className={styles.navigationFilter} />
              {/*{fieldsOfForm.confirmationMode === 'false' && (*/}
              <Button
                theme="red"
                handleClick={() => (fieldsOfForm.confirmationMode === 'false' ? goToConfirmPin() : goToSignUp())}
                customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
              >
                Continue
              </Button>
              {/*// )}*/}
              {/*{fieldsOfForm.confirmationMode === 'true' && (*/}
              {/*  <Button*/}
              {/*    theme="red"*/}
              {/*    handleClick={goToSignUp}*/}
              {/*    customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}*/}
              {/*  >*/}
              {/*    Continue*/}
              {/*  </Button>*/}
              {/*)}*/}
            </div>
          </div>
        </div>
        <IdleMonitor />
      </ContentContainer>
    </Fragment>
  );
};

export default SignUpFirstStepScreen;
