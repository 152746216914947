import { takeLatest, put, all, call } from 'redux-saga/effects';
import _ from 'lodash';

import { GET_KIOSK_SETTINGS_TYPES, getKioskSettingsActionFailure, getKioskSettingsActionSuccess } from '../actions';
import * as Api from '../../../api';

export function* getKioskSettingsSagaWorker({ type, payload }) {
  try {
    const { data } = yield call<any>(Api.getKioskSettings, payload);

    yield put<any>(getKioskSettingsActionSuccess(JSON.parse(data).branding));
  } catch (err) {
    yield put<any>(getKioskSettingsActionFailure());
  }
}

export function* getKioskSettingsSagaWatcher() {
  yield takeLatest(GET_KIOSK_SETTINGS_TYPES.REQUEST, getKioskSettingsSagaWorker);
}

export default function* registrationSettingsSaga() {
  yield all([getKioskSettingsSagaWatcher()]);
}
