import React from 'react';
import styles from './NavigationPanel.module.scss';
import cn from 'classnames';
import Button from '../Button';
import { useSelector } from 'react-redux';

interface NavigationPanelProps {
  onLeftButtonClick?: () => void;
  leftButtonTitle?: string;
  onRightButtonClick?: () => void;
  rightButtonTitle?: string;
  children?: React.ReactNode;
  customClass?: string;
}

const NavigationPanel = ({
  onLeftButtonClick,
  leftButtonTitle,
  onRightButtonClick,
  rightButtonTitle,
  children,
  customClass,
}: NavigationPanelProps) => {
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  return (
    <div className={cn(styles.panel, customClass)}>
      <div className={cn(styles.panelBox, styles.panelBoxLeft)}>
        {onLeftButtonClick && leftButtonTitle && (
          <Button theme="white" handleClick={onLeftButtonClick} customClass={styles.panelButton}>
            {leftButtonTitle}
          </Button>
        )}
      </div>
      <div className={cn(styles.panelBox, styles.panelContent)}>{children}</div>
      <div className={cn(styles.panelBox, styles.panelBoxRight)}>
        {onRightButtonClick && rightButtonTitle && (
          <Button theme="red" handleClick={onRightButtonClick} customClass={styles.panelButton}>
            {rightButtonTitle}
          </Button>
        )}
      </div>
    </div>
  );
};

export default NavigationPanel;
