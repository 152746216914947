export const GET_KIOSK_SETTINGS_TYPES = {
  REQUEST: 'settings/GET_KIOSK_SETTINGS',
  SUCCESS: 'settings/GET_KIOSK_SETTINGS_SUCCESS',
  FAILURE: 'settings/GET_KIOSK_SETTINGS_FAILURE',
};

export const getKioskSettingsAction = (payload: any) => ({
  type: GET_KIOSK_SETTINGS_TYPES.REQUEST,
  payload,
});

export const getKioskSettingsActionSuccess = (payload: any) => ({
  type: GET_KIOSK_SETTINGS_TYPES.SUCCESS,
  payload,
});

export const getKioskSettingsActionFailure = () => ({
  type: GET_KIOSK_SETTINGS_TYPES.FAILURE,
});
