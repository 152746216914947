import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

interface Props {
  theme?: string;
  size?: string;
  customStyles?: {};
  customClass?: string;
  handleClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  blockLevel?: boolean;
  children: React.ReactNode;
}

const Button = ({ theme, size, customStyles, customClass, handleClick, disabled, blockLevel, children }: Props) => {
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  const showCustomBackgroudnColor = (theme === 'red' || theme === 'dark') && primaryColor;

  return (
    <button
      onClick={handleClick}
      className={cn(
        styles.button,
        styles[`${size}Size`],
        styles[`${theme}Theme`],
        { [styles.block]: blockLevel },
        customClass,
      )}
      style={{
        ...customStyles,
        ...(showCustomBackgroudnColor ? { background: primaryColor } : { color: primaryColor }),
        opacity: disabled && primaryColor && 0.5,
      }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  theme: 'default',
  size: 'base',
  customStyles: {},
  handleClick: () => {},
  disabled: false,
};

export default Button;
