import { all } from 'redux-saga/effects';
import { registrationSettingsSaga, terminalSettingsSaga, kioskSettings } from './Settings/sagas';
import { purchaseFlowSaga } from './PurchaseFlow/sagas';
import { terminalSaga } from './Terminal/sagas';
import { sourceSaga } from './Source/sagas';
import {
  logInAnotherWaySaga,
  logOutSaga,
  resetPinSaga,
  newCustomerSaga,
  confiramtionCodeSaga,
  eventsSaga,
  assignActivitiesSaga,
} from './Customer/Sagas';
import {
  addMemberToCustomerGroupSaga,
  removeMemberFromCustomerGroupSaga,
  getCustomerGroupSaga,
} from './CustomerGroup/sagas';
import {
  addMemberToCustomerRelationshipsSaga,
  removeMemberFromCustomerRelationshipsSaga,
  getCustomerRelationshipsSaga,
} from './CustomerRelationships/sagas';
import { combosSaga, getProductCategoriesSaga } from './ProductCategories/sagas';
import { getProductsByCategorySaga } from './ProductsByCategory/sagas';
import { addItemToCartSaga, removeFromCartSaga, removeCartSaga, createCartSaga } from './Cart/sagas';
import {
  startPaymentSaga,
  createPaymentSaga,
  getPaymentStatusSaga,
  makePaymentCardConnectSaga,
  resetPaymentProcessSaga,
  text2PaySaga,
} from './Payment/sagas';
import { createPayByLinkSaga, updateKdsDataSaga } from './PayByLink/sagas';
import { membershipSaga } from './Membership/sagas';

export default function* rootSaga() {
  yield all([
    terminalSaga(),
    registrationSettingsSaga(),
    purchaseFlowSaga(),
    terminalSettingsSaga(),
    kioskSettings(),
    sourceSaga(),
    logInAnotherWaySaga(),
    logOutSaga(),
    resetPinSaga(),
    newCustomerSaga(),
    addMemberToCustomerGroupSaga(),
    removeMemberFromCustomerGroupSaga(),
    getCustomerGroupSaga(),
    addMemberToCustomerRelationshipsSaga(),
    removeMemberFromCustomerRelationshipsSaga(),
    getCustomerRelationshipsSaga(),
    getProductCategoriesSaga(),
    combosSaga(),
    getProductsByCategorySaga(),
    addItemToCartSaga(),
    removeFromCartSaga(),
    removeCartSaga(),
    startPaymentSaga(),
    createPaymentSaga(),
    getPaymentStatusSaga(),
    createPayByLinkSaga(),
    updateKdsDataSaga(),
    makePaymentCardConnectSaga(),
    resetPaymentProcessSaga(),
    text2PaySaga(),
    createCartSaga(),
    membershipSaga(),
    confiramtionCodeSaga(),
    eventsSaga(),
    assignActivitiesSaga(),
  ]);
}
