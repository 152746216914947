import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../App';
import AddGuestForm from '../../components/AddGuestForm';
import AddWaiverToGuest from '../../components/AssignActivities/AddWaiverToGuest/AddWaiverToGuest';
import SelectActivitiesGuests from '../../components/AssignActivities/SelectActivitiesGuests/SelectActivitiesGuests';
import WaiverWarningModal from '../../components/AssignActivities/WaiverWarningModal/WaiverWarningModal';
import Button from '../../components/Button';
import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import UpdateBirthDate from '../../components/UpdateBirthDate/UpdateBirthDate';
import IdleMonitor from '../../services/IdleMonitor';
import { ActivityGuestType } from '../../store/Customer/types/assignActivities';
import { getCustomerGroupAction } from '../../store/CustomerGroup/actions';
import { getCustomerGroupActionReset } from '../../store/CustomerGroup/actions/getCustomerGroupActions';
import styles from './styles.module.scss';
import * as Api from '../../api';
import AssignActivitiesModal from '../../components/AssignActivities/AssignActivitiesModal/AssignActivitiesModal';
import CheckInComplete from '../../components/AssignActivities/CheckInComplete/CheckInComplete';
import { confirmationCodeCheckReset, saveCustomerToStoreAction } from '../../store/Customer/actions';
import {
  CheckGuestRegistrationInActivityAction,
  getActivitiesByConfirmationCodeAction,
  getActivitiesByConfirmationCodeReset,
} from '../../store/Customer/actions/assignActivitiesActions';
import IconWithText from '../../components/IconWithText';
import { ReactComponent as AddNewButton } from '../../assets/icons/add-new-button.svg';

enum ScreenModes {
  SelectGuests = 1,
  AddWaiverToGuest = 2,
  AddNewGuest = 3,
  SuccessPage = 4,
}

const AssignActivities = () => {
  const dispatch = useDispatch();
  const [errorModal, setErrorModal] = useState<string | null>(null);
  const [screen, setScreen] = useState(ScreenModes.SelectGuests);
  const [isWaiverAddPending, setWaiverAddPending] = useState<boolean>(false);
  const [guestRemovePending, setGuestRemovePending] = useState<boolean>(false);
  const {
    assignActivitiesReducer,
    customerStore,
    customerGroup,
    terminalSettings,
    confirmationCodeReducer,
  } = useSelector(state => state);
  const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);
  const [guestsWithIncompleteWaiver, setGuestsWithIncompleteWaiver] = useState<ActivityGuestType[]>([]);
  const [visibleActivityId, setVisibleActivityId] = useState<number | null>(null);
  const [activitiesGuest, setActivitiesGuests] = useState<{ [key: number]: ActivityGuestType[] | null }>(null);
  const [currentActivityNumber, setCurrentActivityNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const activities = assignActivitiesReducer?.activities?.data;
  const totalActivities = activities?.length;
  const isFirstLoad = useRef(true);

  const generateGuestsWithIncompleteWaiver = () => {
    const res: ActivityGuestType[] = [];
    Object.keys(activitiesGuest).forEach(x => {
      activitiesGuest[x].forEach(guest => {
        if (guest.status1 !== 2 && !res.find(el => el.custId === guest.custId)) {
          res.push(guest);
        }
      });
    });
    return res;
  };

  useEffect(() => {
    if (
      customerGroup?.members &&
      assignActivitiesReducer?.activities?.data &&
      !assignActivitiesReducer.selectedGuestsForActitivies
    ) {
      setLoading(true);

      // get all custIds
      const customerIds = customerGroup?.members?.map(member => {
        return member.custId;
      });
      customerIds.push(customerStore.custId);

      // get all activityIds
      const activityIds = assignActivitiesReducer?.activities?.data?.map(member => {
        return member.activityId;
      });

      dispatch(CheckGuestRegistrationInActivityAction({ customerIds, activityIds }));
      setLoading(false);
    }
  }, [currentActivityNumber, assignActivitiesReducer, customerGroup]);

  const handleNext = async () => {
    if (screen === ScreenModes.SelectGuests) {
      if (totalActivities === 1 && activitiesGuest?.[visibleActivityId]?.length === 0) {
        setErrorModal('Select minimum one guest');
        return;
      }
      if (
        totalActivities > 1 &&
        currentActivityNumber + 1 === totalActivities &&
        Object.keys(activitiesGuest).reduce((acc, cur) => acc + activitiesGuest[cur].length, 0) === 0
      ) {
        setErrorModal('Select minimum one guest');
        return;
      }
    }

    if (screen === ScreenModes.SelectGuests && currentActivityNumber + 2 <= totalActivities) {
      const newActivityId = activities[currentActivityNumber + 1].activityId;
      setVisibleActivityId(newActivityId);
      setCurrentActivityNumber(prev => prev + 1);
    }

    if (screen === ScreenModes.SelectGuests && currentActivityNumber + 1 === totalActivities) {
      const guestsWithoutWaivers = generateGuestsWithIncompleteWaiver();
      if (guestsWithoutWaivers.length) {
        setGuestsWithIncompleteWaiver(guestsWithoutWaivers);
        setOpenWarningModal(true);
      } else {
        try {
          setLoading(true);
          const reqData = {
            terminalNumber: terminalSettings.terminalNumber,
            confirmationNumber: confirmationCodeReducer?.confirmationNumber,
            activityGuests: Object.keys(activitiesGuest).reduce(
              (acc, cur) => [...acc, { activityId: cur, customerIdsList: activitiesGuest[cur].map(el => el.custId) }],
              [],
            ),
          };
          const res = await Api.addGuestsInActivity(reqData);
          if (!res.data?.success) {
            setErrorModal(res.data?.message);
            dispatch(getActivitiesByConfirmationCodeAction(confirmationCodeReducer?.confirmationNumber));
            setScreen(ScreenModes.SelectGuests);
          } else {
            setScreen(ScreenModes.SuccessPage);
            dispatch(confirmationCodeCheckReset());
            dispatch(getActivitiesByConfirmationCodeReset());
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log('Something Went Wrong', error.message);
        }
      }
    }
  };

  const handleBack = () => {
    if (currentActivityNumber > 0) {
      setVisibleActivityId(activities[currentActivityNumber - 1].activityId);
      setCurrentActivityNumber(prev => prev - 1);
    } else {
      history.push('/');
    }
  };

  const handleWaiverFinish = async () => {
    const currentUser = { ...guestsWithIncompleteWaiver[0] };
    const newData = [...guestsWithIncompleteWaiver].slice(1);
    const newActivityGuest = { ...activitiesGuest };
    // update waiver status in local state
    Object.keys(newActivityGuest).forEach(el => {
      newActivityGuest[el].forEach(el => {
        if (el.custId === currentUser.custId) {
          el.status1 = 2;
        }
      });
    });
    setActivitiesGuests({ ...newActivityGuest });
    setGuestsWithIncompleteWaiver([...newData]);
    if (newData.length === 0) {
      setWaiverAddPending(true);
      dispatch(getCustomerGroupActionReset());
      dispatch(getCustomerGroupAction({ custId: customerStore.custId }));
      handleNext();
      try {
        const reqData = {
          terminalNumber: terminalSettings.terminalNumber,
          confirmationNumber: confirmationCodeReducer?.confirmationNumber,
          activityGuests: Object.keys(activitiesGuest).reduce(
            (acc, cur) => [...acc, { activityId: cur, customerIdsList: activitiesGuest[cur].map(el => el.custId) }],
            [],
          ),
        };
        const res = await Api.addGuestsInActivity(reqData);
        if (!res.data?.success) {
          setErrorModal(res.data?.message);
          dispatch(getActivitiesByConfirmationCodeAction(confirmationCodeReducer?.confirmationNumber));
          setScreen(ScreenModes.SelectGuests);
        } else {
          setScreen(ScreenModes.SuccessPage);
          dispatch(confirmationCodeCheckReset());
          dispatch(getActivitiesByConfirmationCodeReset());
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (assignActivitiesReducer?.activities?.data) {
      setVisibleActivityId(activities[0]?.activityId);
    }
  }, [assignActivitiesReducer]);

  useEffect(() => {
    if (activities) {
      const res = activities.reduce((acc, cur) => ({ ...acc, [cur.activityId]: [] }), {});
      setActivitiesGuests({ ...res });
    }
  }, [assignActivitiesReducer]);

  //get activities

  useEffect(() => {
    dispatch(getCustomerGroupAction({ custId: customerStore.custId }));
    dispatch(getActivitiesByConfirmationCodeAction(confirmationCodeReducer?.confirmationNumber));
    if (!confirmationCodeReducer) {
      history.push('/');
    }
  }, []);

  const updateCustomerStore = async () => {
    if (confirmationCodeReducer?.data?.custId !== customerStore?.custId) {
      try {
        const { data: customerData } = await Api.fetchCustomerById(customerStore.custId);
        dispatch(saveCustomerToStoreAction(customerData));
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (confirmationCodeReducer && customerStore && isFirstLoad.current) {
      isFirstLoad.current = false;
      updateCustomerStore();
    }
  }, [confirmationCodeReducer, customerStore]);

  return (
    <>
      <WaiverWarningModal
        open={openWarningModal}
        toggle={() => setOpenWarningModal(!openWarningModal)}
        showWaiverScreen={() => setScreen(ScreenModes.AddWaiverToGuest)}
        data={guestsWithIncompleteWaiver}
      />
      <AssignActivitiesModal open={Boolean(errorModal)} toggle={() => setErrorModal(null)} message={errorModal} />
      {terminalSettings?.checkInType?.onlineBooking &&
      confirmationCodeReducer?.confirmationNumber &&
      confirmationCodeReducer?.data?.phoneNumber === customerStore.phoneNumber &&
      new Date(customerStore.birthDate).getFullYear() < 1800 ? (
        <UpdateBirthDate />
      ) : (
        <ContentContainer
          loading={
            (customerGroup.loading ||
              guestRemovePending ||
              isWaiverAddPending ||
              !activities ||
              !customerStore.custId ||
              loading ||
              !assignActivitiesReducer.selectedGuestsForActitivies) &&
            screen !== ScreenModes.SuccessPage
          }
        >
          <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />
          {screen === ScreenModes.SelectGuests && visibleActivityId && (
            <>
              <div className={styles.assignAcitivitiesHeaderWrapper}>
                <h1 className={styles.title}>Assign Activities</h1>
                <p className={styles.selectedGuestsNumber}>
                  {currentActivityNumber + 1} of {totalActivities}
                </p>
                <div className={styles.cardTitleWrapper}>
                  <IconWithText
                    icon={AddNewButton}
                    text="ADD MORE GUESTS"
                    color="red"
                    onClick={() => setScreen(ScreenModes.AddNewGuest)}
                    buttonIconClass={styles.addGuestButton}
                    buttontextClass={styles.iconButtonText}
                  />
                </div>
              </div>
              <SelectActivitiesGuests
                setGuestRemovePending={setGuestRemovePending}
                activitiesGuest={activitiesGuest}
                setActivitiesGuests={setActivitiesGuests}
                data={activities && { ...activities[currentActivityNumber] }}
              />
              <div className={styles.navigation}>
                <Button handleClick={handleBack} customClass={styles.navigationButton}>
                  {currentActivityNumber === 0 ? 'Cancel' : 'Back'}
                </Button>
                <div className={styles.btnWrapper}>
                  <Button handleClick={handleNext} customClass={styles.navigationButton} theme="red">
                    Continue
                  </Button>
                </div>
              </div>
            </>
          )}
          {screen === ScreenModes.AddNewGuest && (
            <AddGuestForm
              handleHomeClick={() => setScreen(ScreenModes.SelectGuests)}
              endAddingGuestProcessHandler={() => setScreen(ScreenModes.SelectGuests)}
            />
          )}
          {screen === ScreenModes.AddWaiverToGuest && guestsWithIncompleteWaiver.length > 0 && (
            <AddWaiverToGuest
              setWaiverAddPending={setWaiverAddPending}
              customer={guestsWithIncompleteWaiver[0]}
              onFinish={() => {
                handleWaiverFinish();
              }}
              closeWaiver={() => {
                setScreen(ScreenModes.SelectGuests);
              }}
            />
          )}
          {screen === ScreenModes.SuccessPage && <CheckInComplete />}
          <IdleMonitor />
        </ContentContainer>
      )}
    </>
  );
};

export default AssignActivities;
