import React, { useState, useRef, useCallback, useEffect } from 'react';
import Webcam from 'react-webcam';
import Button from '../Button';
import styles from './styles.module.scss';
import CountdownTimerPhoto from '../CountdownTimerPhoto';
import { useSelector } from 'react-redux';

interface Props {
  fieldName: string;
  value: any;
  onChange: (fieldName: string, newValue: string) => void;
}

const WizardFormPicture = ({ fieldName, value, onChange }: Props) => {
  const videoRef = useRef(null);
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  const [currentPhoto, setCurrentPhoto] = useState(value);
  const [startCountdown, setStartCountdown] = useState(false);

  const takePhoto = useCallback(() => {
    const imageSrc = videoRef.current.getScreenshot();
    onChange(fieldName, imageSrc);
    setCurrentPhoto(imageSrc);
    setStartCountdown(false);
  }, [videoRef, setCurrentPhoto]);

  const takeAnotherPhoto = () => {
    onChange(fieldName, '');
    setCurrentPhoto('');
    setStartCountdown(true);
  };

  const takePhotoButtonHandler = () => {
    if (videoRef?.current?.state?.hasUserMedia) {
      setStartCountdown(true);
    }
  };

  return (
    <div className={styles.wizardFormPicture}>
      <div className={styles.videoContainer}>
        <div className={styles.videoContainerInner}>
          <div className={styles.video}>
            <Webcam audio={false} ref={videoRef} screenshotFormat="image/jpeg" />
          </div>
          {currentPhoto && <img className={styles.photo} src={currentPhoto} alt="" />}
        </div>
        {startCountdown && <CountdownTimerPhoto time={4} onComplete={takePhoto} />}
      </div>
      {!currentPhoto && (
        <Button theme="red" customClass={styles.button} handleClick={() => takePhotoButtonHandler()}>
          Take Picture
        </Button>
      )}
      {currentPhoto && (
        <Button theme="red" customClass={styles.button} handleClick={takeAnotherPhoto}>
          Take Another Picture
        </Button>
      )}
    </div>
  );
};

WizardFormPicture.defaultProps = {};

export default WizardFormPicture;
