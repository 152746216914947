import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Api from '../../api';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import { saveCustomerToStoreAction, updateSignatureOfNewCustomerAction } from '../../store/Customer/actions';
import isAdult from '../../utils/isAdult';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import ReactSignatureCanvas from 'react-signature-canvas';
import Button from '../../components/Button';
import styles from './styles.module.scss';
import Checkbox from '../../components/Checkbox';
import WaiverAgreementText from '../../components/WaiverAgreementText';

const EnforceWaiverAgreementMinorScreen = () => {
  const dispatch = useDispatch();
  const sigPad = React.useRef(null);

  const {
    newCustomerStore,
    newGuestStore,
    registrationSettings: { adultAge, waiverStep },
  } = useSelector(state => state);
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  const newAdultGuests = [...newGuestStore.guests].filter(
    (item: any) => isAdult(item.commonData.birthDate, adultAge) && !item.custId,
  );
  const [agreeWithRules, setAgreeWithRules] = useState(false);
  const [loading, setLoading] = useState(false);

  const startRegistrationFlow = async (listOfNewGuests, signatureOfPrimaryCustomer) => {
    try {
      setLoading(true);
      const listOfNewMinorGuests = listOfNewGuests.filter(item => !isAdult(item.commonData.birthDate, adultAge));
      const { data: primaryCust } = await Api.createCustomer(newCustomerStore.commonData);

      await Api.createAdultWaiver({
        signature: signatureOfPrimaryCustomer,
        adultCustId: primaryCust.custId,
      });

      for (const item of listOfNewMinorGuests) {
        const {
          data: { custId: curentCustId },
        } = await Api.createCustomer(item.commonData);
        await Api.createMinorWaiver({
          guardianSignature: signatureOfPrimaryCustomer,
          minorCustId: curentCustId,
          guardianCustId: primaryCust.custId,
        });
        await Api.addToCustomerGroup({
          custIds: [curentCustId],
          createdById: primaryCust.custId,
        });
        await Api.addToCustomerRelationships({
          relationships: [
            {
              relationship: item.relationships,
              custId: curentCustId,
            },
          ],
          custId: primaryCust.custId,
        });
      }
      setLoading(false);
      dispatch(saveCustomerToStoreAction(primaryCust));
    } catch (e) {
      setLoading(false);
    }
  };

  const onBackClick = () => {
    history.goBack();
  };

  const onContinueClick = async () => {
    try {
      const listOfNewGuests = newGuestStore.guests;
      const signatureOfPrimaryCustomer = sigPad.current.toDataURL().substr(22);
      if (!agreeWithRules && sigPad.current.isEmpty()) {
        return;
      }
      dispatch(updateSignatureOfNewCustomerAction(signatureOfPrimaryCustomer));

      if (newAdultGuests.length > 0) {
        history.push(`${ROUTES.WAIVER_AGREEMENT_ADULT}/${newAdultGuests[0].id}`);
        return;
      }
      await startRegistrationFlow(listOfNewGuests, signatureOfPrimaryCustomer);
      history.push(ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE);
    } catch (e) {}
  };

  return (
    <Fragment>
      <ContentContainer loading={loading}>
        <HeaderPanel startOverButton border paginationHistory="" paginationStep={''} />
        <div className={styles.content}>
          <div className={styles.waiverAgreementTextContainer}>
            <span className={styles.waiverAgreementTextTitle}>
              Waiver Agreement - {newCustomerStore.commonData.fName} {newCustomerStore.commonData.lName} (signing for
              minors in group)
            </span>
            <WaiverAgreementText waiverText={waiverStep.minorWaiverText} />
          </div>

          <div className={styles.waiverAgreement}>
            <div>
              <Checkbox
                state={!!agreeWithRules}
                label={`I, ${newCustomerStore.commonData.fName} ${newCustomerStore.commonData.lName}, agree to the terms and conditions of the above agreement and acknowledge that the signature below will apply to both my own waiver, and the waiver for any minors present in my group.`}
                handleClick={() => setAgreeWithRules(prevState => !prevState)}
              />
            </div>
          </div>

          <div className={styles.waiverAgreementSignatureContainer}>
            <span className={styles.waiverAgreementSignatureTitle}>
              Waiver Signature - {newCustomerStore.commonData.fName} {newCustomerStore.commonData.lName}
            </span>
            <div className={styles.waiverAgreementSignatureCanvas}>
              <div className={styles.waiverAgreementSignatureCanvasInner}>
                <ReactSignatureCanvas canvasProps={{ width: 550, height: 250 }} backgroundColor="white" ref={sigPad} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.navigation}>
          <Button theme="white" size="base" handleClick={onBackClick} customClass={styles.whiteButton}>
            Back
          </Button>
          <div className={styles.navigationFilter} />
          <Button theme="red" size="base" handleClick={onContinueClick} customClass={styles.redButton}>
            Continue
          </Button>
        </div>
      </ContentContainer>
      <IdleMonitor />
    </Fragment>
  );
};

export default EnforceWaiverAgreementMinorScreen;
