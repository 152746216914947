import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { findIndex } from 'lodash';
import { uniqueId } from 'lodash';
import * as Api from '../../api';
import { history } from '../../App';

import {
  addItemToCartAction,
  addNonschedulableProductsToCart,
  removeItemFromCartAction,
} from '../../store/Cart/actions';
import update from 'immutability-helper';
import Prompts from '../../components/Prompts';

import {
  hideSelectionOfParticipant,
  showSelectionOfParticipant,
  updateStatusNotEnoughTimeSlots,
} from '../../store/PurchaseFlow/actions';

import ContentContainer from '../../components/ContentContainer';
import IdleMonitor from '../../services/IdleMonitor';
import HeaderPanel from '../../components/HeaderPanel';
import Cart from '../../components/Cart';
import ChooseParticipant from '../../components/ChooseParticipant';
import AddGuestForm from '../../components/AddGuestForm';
import WarningAlert from '../../components/WarningAlert';
import Button from '../../components/Button';
import EnforceWaiver from '../../components/EnforceWaiver';
import Confirm from '../../components/Prompt';
import styles from './styles.module.scss';
import ProductMembershipRequired from '../../components/ProductMembershipRequired';
import { getItemsApiCredentials, getPrice } from '../../utils/core';
import ProductSchedule from '../../components/ProductSchedule';
import { ROUTES } from '../../constants';
import { getProductsByComboAction } from 'store/ProductsByCategory/actions';
import { Member } from 'typings/product';
import { comboCategory } from 'config/combos';

const ComboDetailsScreen = () => {
  // @ts-ignore
  const { categoryId, comboId } = useParams();
  const dispatch = useDispatch();
  const {
    terminalSettings: { checkInType },
    customerStore,
    customerGroup: customerGroupState,
    cartStore: { loading: cartLoading, checkId, comboCheckDetails, comboCheckDetailsPerResource },
    purchaseFlowStore: { selectionOfParticipant, notEnoughTimeSlots, loading: purchaseFlowLoading },
    membershipStore,
    comboProducts: { combos },
    productsByCategoryStore: { productsByCombo, loading: comboProductsLoading },
  } = useSelector(state => state);
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  const [loading, setLoading] = React.useState(false);

  const [productDetail, setProductDetail] = React.useState({
    productId: null,
    productID: null,
    title: '',
    description: '',
    largeIcon: '',
    highResImagePath: '',
    price1: 0,
    productType: 0,
    isRequiredMembership: false,
    isSchedulable: false,
    explanation: '',
  });

  const [comboGroupId, setComboGroupId] = useState(Number(uniqueId()));

  const [productPack, setProductPack] = React.useState(0);
  const [timeSlots, setTimeSlots] = React.useState([]);
  const [activeProductIndex, setActiveProductIndex] = useState(0);
  const [showListOfGuests, setShowListOfGuests] = useState(selectionOfParticipant);
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);

  const scheduledDate = moment().format('YYYY-MM-DD');
  const [selectedTimeSlots, setSelectedTimeSlots] = React.useState([]);

  const [showGuestsLimitationWarning, setShowGuestsLimitationWarning] = React.useState(false);
  const [showAddGuest, setShowAddGuest] = React.useState(false);
  const [showDialogModal, setShowDialogModal] = useState(false);
  const [membersWithoutWaiver, setMembersWithoutWaiver] = React.useState([]);
  const [incompleteWaiver, setIncompleteWaiver] = React.useState(false);
  const [enforceWaiverInProcess, setEnforceWaiverInProcess] = React.useState(false);
  const [prompts, setPrompts] = React.useState(null);
  const [showPrompts, setShowPrompts] = React.useState(false);
  const [qty, setQty] = React.useState(0);
  const [promptTempMembers, setPromptTempMembers] = React.useState(null);
  const [isNonSchedulableAdded, setIsNonSchedulableAdded] = useState(false);

  const currentCombo = useMemo(() => {
    const findCombo = combos.find(combo => combo.categoryID == comboId);
    return findCombo;
  }, [combos]);

  const comboProductsList = useMemo(() => {
    if (Array.isArray(productsByCombo)) {
      return productsByCombo?.filter(product => product.comboID == comboId);
    }
    return [];
  }, [productsByCombo]);

  const fetchTimeSlots = async (selectedScheduledDates = null, productComboId = 0) => {
    try {
      setLoading(true);
      let productIdParam = String(productComboId);

      const custIds = selectedMembers.map(member => member.custId);

      const { data: dataTimeSlots } = await Api.fetchTimeSlots(
        scheduledDate,
        productIdParam,
        selectedScheduledDates,
        checkId,
        custIds,
      );
      setTimeSlots(dataTimeSlots);
      setLoading(false);

      return dataTimeSlots;
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch combo products
  React.useEffect(() => {
    const fetchCombos = async () => {
      try {
        const { clientId, clientSecret, scope } = getItemsApiCredentials();
        dispatch(
          getProductsByComboAction({
            clientId,
            clientSecret,
            scope,
            comboId: String(comboId),
          }),
        );
      } catch {
        throw new Error('error! No browser support');
      }
    };

    // Show guest modal
    setShowListOfGuests(true);
    if (!comboProductsList.length) {
      fetchCombos();
    }
  }, []);

  // change non-schedulable products
  const nonschedulableProductsHandler = async () => {
    const custIds = selectedMembers.map(member => member.custId) as string[];
    const nonschedulableProducts = comboProductsList.filter(product => !product.isSchedulable);
    const schedulableProduct = comboProductsList.find(product => product.isSchedulable);

    await dispatch(
      addNonschedulableProductsToCart({
        checkId,
        comboId,
        custIds,
        comboGroupId,
        products: nonschedulableProducts,
        isPerResource: schedulableProduct?.isPerResource || false,
        areOnlyNonschedulableProducts: nonschedulableProducts.length === comboProductsList.length,
      }),
    );
  };

  React.useEffect(() => {
    const initProductData = async () => {
      setLoading(true);

      if (comboProductsList?.length === 0 && selectedMembers.length) {
        history.replace(`${ROUTES.PRODUCTS}/${comboCategory.id}`, {
          title: comboCategory.id,
        });
        return;
      }

      if (comboProductsList?.length === 0 && !selectedMembers.length) {
        setLoading(false);
        return;
      }

      setProductDetail(comboProductsList?.[activeProductIndex]);
      setProductPack(comboProductsList?.[activeProductIndex].pack);

      if (!activeProductIndex && comboProductsList?.length && selectedMembers.length) {
        await nonschedulableProductsHandler();
      }

      if (
        comboProductsList?.[activeProductIndex]?.productID &&
        comboProductsList?.[activeProductIndex]?.isSchedulable &&
        !!selectedMembers.length
      ) {
        await fetchTimeSlots(null, comboProductsList?.[activeProductIndex].productID);
      }
      setLoading(false);
    };

    if (!incompleteWaiver && !comboProductsLoading) {
      initProductData();
    }
  }, [activeProductIndex, comboProductsList, selectedMembers]);

  React.useEffect(() => {
    if (!!notEnoughTimeSlots) {
      setLoading(false);
    }
  }, [notEnoughTimeSlots]);

  // Checking timeslots
  const timeSlotHandler = async ({ item, trackNo }) => {
    setLoading(true);
    const index = findIndex(selectedTimeSlots, (i: any) => {
      return i.scheduledTime === item.scheduledTime && i.trackNo === trackNo;
    });

    // Remove/Clear selected timeslot
    if (index >= 0) {
      const a = selectedTimeSlots.filter((i: any) => {
        return i.scheduledTime !== item.scheduledTime;
      });
      await fetchTimeSlots(
        a.map(time => {
          return {
            selectedScheduledDate: time.scheduledTime,
            ...(time.trackNo && { trackNo: time.trackNo }),
          };
        }),
        comboProductsList?.[activeProductIndex].productID,
      );
      return setSelectedTimeSlots([...a]);
    }
    if (item.timeSlotStatus === 0) {
      // Do not select more timeslots than user has
      if (selectedTimeSlots.length === productPack) {
        setLoading(false);
        return;
      }

      const selected = [...selectedTimeSlots, { ...item, ...(trackNo && { trackNo }) }];
      await fetchTimeSlots(
        selected.map(time => {
          return {
            selectedScheduledDate: time.scheduledTime,
            ...(time.trackNo && { trackNo: time.trackNo }),
          };
        }),
        comboProductsList?.[activeProductIndex].productID,
      );
      setSelectedTimeSlots(selected);
    }

    setLoading(false);
  };

  // Next button handler
  const nextClickHandler = async () => {
    try {
      setLoading(true);
      await doneClickHandler(selectedMembers);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const cancelClickHandler = () => {
    setShowListOfGuests(false);
    dispatch(hideSelectionOfParticipant());
  };

  const checkWaivers = async members => {
    setMembersWithoutWaiver([]);
    const arr = [];
    for (const member of members) {
      const findMember = members.find(customer => customer.custId === member.custId);
      if (findMember.status1 !== 2) {
        arr.push(findMember);
      }
    }

    if (arr.length > 0) {
      setIncompleteWaiver(true);
      setMembersWithoutWaiver(arr);

      return false;
    } else {
      return true;
    }
  };

  const changeProduct = () => {
    const findSchedulableProduct = comboProductsList.findIndex(
      (product, index) => product.isSchedulable && index > activeProductIndex,
    );

    if (findSchedulableProduct !== -1) {
      setActiveProductIndex(findSchedulableProduct);
      setSelectedTimeSlots([]);
    } else {
      history.replace(`${ROUTES.PRODUCTS}/${comboCategory.id}`, {
        title: comboCategory.id,
      });
    }

    // clean up
    setPrompts(null);
    setLoading(false);
  };

  const finishEnforceWaiver = async membersEnforce => {
    setShowListOfGuests(false);
    setEnforceWaiverInProcess(false);
  };

  const closeEnforceWaiver = () => {
    setEnforceWaiverInProcess(false);
  };

  const addToCart = async members => {
    const arr = [];
    const productDetails = comboProductsList?.[activeProductIndex];

    members.map(item => {
      const itemData = {
        productId: productDetails.productId || productDetails.productID,
        productForCustId: item.custId,
        productComboId: productDetails?.comboID,
        activitiesToSchedule: selectedTimeSlots.map((i: any) => {
          return {
            ...(i.trackNo && { trackNo: i.trackNo }),
            activityId: i.activityId,
            scheduledTime: i.scheduledTime,
          };
        }),
        custId: item.custId,
        quantity: 1,
      };
      arr.push(itemData);
    });

    await dispatch(
      addItemToCartAction({
        productType: productDetails.productType,
        checkDetails: [...arr],
        isRequiredMembership: productDetails.isRequiredMembership,
        isSchedulable: productDetails.isSchedulable,
        isComboProduct: true,
        data: comboProductsList?.[activeProductIndex],
        isPerResource: productDetails.isPerResource,
        comboGroupId,
        onSuccess: () => {
          changeProduct();
        },
      }),
    );
  };

  const setPromptsData = ({ quantity, promptData, members = null }) => {
    setQty(quantity);
    if (members) {
      setPromptTempMembers(members);
    }
    const prompts = promptData.map((prompt, idx): any => {
      const labels = prompt.promptLabels.map((label): any => {
        return {
          ...label,
          checked: false,
        };
      });
      return {
        ...prompt,
        isOpen: idx === 0,
        promptLabels: labels,
        disabledContinue: labels && labels.length,
      };
    });
    setPrompts(prompts);
    setShowPrompts(true);
  };

  // Select Members
  const changeSelectedMembers = async members => {
    if (members.length > currentCombo.maximumGuests || members.length < currentCombo.minimumGuests) {
      setShowGuestsLimitationWarning(true);
      return;
    }

    setSelectedMembers(members);
    const doAllMembersHaveSignatures = await checkWaivers(members);

    if (doAllMembersHaveSignatures) {
      setShowListOfGuests(false);
    }
  };

  const doneClickHandler = async (members = selectedMembers) => {
    try {
      const newTimeslots = await fetchTimeSlots(
        selectedTimeSlots.map(time => {
          return {
            selectedScheduledDate: time.scheduledTime,
            ...(time.trackNo && { trackNo: time.trackNo }),
          };
        }),
        comboProductsList?.[activeProductIndex].productID,
      );

      let minSlotsRemaining = Number.MAX_SAFE_INTEGER;

      newTimeslots.forEach((slots: any) => {
        slots.timeslots.forEach((slot: any) => {
          const selectedScheduleTime = selectedTimeSlots.find(
            selectedSlot => selectedSlot.scheduledTime === slot.scheduledTime,
          );

          if (
            selectedScheduleTime &&
            // selectedScheduleTime?.trackNo === trackNo &&
            slot.slotsRemaining < minSlotsRemaining
          ) {
            minSlotsRemaining = slot.slotsRemaining;
          }
        });
      });

      if (minSlotsRemaining < selectedMembers.length) {
        dispatch(updateStatusNotEnoughTimeSlots({ notEnoughTimeSlots: true }));
        return false;
      }

      const {
        data: { prompts: promptsData },
      } = await Api.fetchProductPrompts(productDetail?.productId || productDetail.productID);

      if (!promptsData?.length || !!prompts?.length) {
        addToCart(members);
        return true;
      } else {
        setPromptsData({ members, quantity: 1, promptData: promptsData });
        return false;
      }
    } catch (e) {
      console.log({ e });
    }
  };

  const cancelCombos = () => {
    const custIds = selectedMembers.map(member => member.custId);

    dispatch(
      removeItemFromCartAction({
        isComboProduct: true,
        custIds,
        comboGroupId,
        comboId: comboProductsList?.[activeProductIndex].comboID,
        min: currentCombo?.minimumGuests,
        max: currentCombo?.maximumGuests,
      }),
    );

    // history.replace(`${ROUTES.PRODUCTS}/${comboCategory.id}`, {
    //   title: comboCategory.id,
    // });
  };

  React.useEffect(() => {
    if (membershipStore.customers) {
      setLoading(false);
    }
  }, [membershipStore.customers]);

  const showNextButton = () => {
    if (!selectedTimeSlots.length) {
      return false;
    }

    if (productPack !== selectedTimeSlots.length && checkInType.maximumSelection) {
      return false;
    }

    return true;
  };

  return (
    <>
      <ContentContainer
        loading={
          loading ||
          cartLoading ||
          purchaseFlowLoading ||
          membershipStore.isLoading ||
          (comboProductsLoading && !!selectedMembers.length)
        }
      >
        <HeaderPanel handleBackClick={() => history.goBack()} logOutButton border />

        <div className={styles.container}>
          <div className={styles.content}>
            <div id="productTitle" className={styles.panel}>
              <h2 className={styles.title}>{productDetail?.description || ''}</h2>
              <div className={styles.price}>
                <div className={styles.priceInner}>${getPrice(productDetail, customerStore.priceLevel, true)}</div>
              </div>
            </div>
            <div id="productDetail" className={styles.productInformation}>
              <div className={styles.productCover}>
                <img
                  src={
                    productDetail.highResImagePath
                      ? productDetail.highResImagePath
                      : `data:image/jpeg;base64,${productDetail.largeIcon}`
                  }
                  alt=""
                />
              </div>
              <div className={styles.productDescription}>
                <div>{productDetail.explanation}</div>
              </div>
              {!!productPack && comboProductsList?.[activeProductIndex]?.pack > 1 ? (
                <div className={styles.packSlotsCounter} style={{ background: primaryColor || '#df151c' }}>
                  {!selectedTimeSlots.length &&
                    (checkInType.maximumSelection ? (
                      <span>Choose {productPack} Time Slots</span>
                    ) : (
                      <span>Choose up to {productPack} Time Slots</span>
                    ))}
                  {!!selectedTimeSlots.length && (
                    <span>{productPack - selectedTimeSlots.length} Time Slots Remaining</span>
                  )}
                </div>
              ) : null}
            </div>
            <ProductSchedule
              selectedTimeSlots={selectedTimeSlots}
              timeSlots={timeSlots}
              timeSlotHandler={timeSlotHandler}
              resourceGroup={timeSlots.length > 1}
            />
            <div id="footerPanel" className={styles.footerPanel}>
              <Button
                customClass={styles.cancelButton}
                handleClick={() => {
                  setShowDialogModal(true);
                }}
                theme="white"
              >
                Cancel
              </Button>
              {showNextButton() && (
                <Button customClass={styles.nextButton} handleClick={nextClickHandler} theme="red">
                  Next
                </Button>
              )}
            </div>
          </div>
          <Cart members={selectedMembers} comboGroupId={comboGroupId} />
          {showAddGuest && (
            <AddGuestForm
              endAddingGuestProcessHandler={() => {
                setShowAddGuest(false);
                setShowListOfGuests(true);
              }}
            />
          )}
        </div>
      </ContentContainer>
      {showListOfGuests && (
        <ChooseParticipant
          isOpen={showListOfGuests}
          members={[customerStore, ...customerGroupState.members]}
          cancelClickHandler={() =>
            history.replace(`${ROUTES.PRODUCTS}/${comboCategory.id}`, {
              title: comboCategory.id,
            })
          }
          addGuestHandler={() => {
            setShowListOfGuests(false);
            setShowAddGuest(true);
          }}
          doneClickHandler={changeSelectedMembers}
        />
      )}
      {enforceWaiverInProcess && (
        <EnforceWaiver
          membersWithoutWaiver={membersWithoutWaiver}
          members={selectedMembers}
          finishEnforceWaiver={finishEnforceWaiver}
          closeEnforceWaiver={closeEnforceWaiver}
        />
      )}
      <Confirm
        isOpen={incompleteWaiver}
        title="Incomplete Waiver"
        titleButtonCancel="Return"
        titleButtonOk="Continue"
        handleReturnClick={() => setIncompleteWaiver(false)}
        handleContinueClick={() => {
          setIncompleteWaiver(false);
          setEnforceWaiverInProcess(true);
        }}
      >
        <div className={styles.incompleteWaiver}>
          <p>The following guests do not have the required waiver:</p>
          <ul>
            {membersWithoutWaiver.map((item: any) => {
              return (
                <li key={item.custId}>
                  {item.fName} {item.lName}
                </li>
              );
            })}
          </ul>
          <p>- Continue to complete the waiver, or return to change your selection</p>
        </div>
      </Confirm>

      {showPrompts && (
        <Prompts
          setQty={setQty}
          setPromptTempMembers={setPromptTempMembers}
          setPrompts={setPrompts}
          closePromptModal={() => setShowPrompts(false)}
          handleContinueClick={async idx => {
            const uncheckedPrompts = prompts.findIndex(item => item.disabledContinue);
            if (uncheckedPrompts !== -1) {
              const newPromptsData = update(prompts, {
                [idx]: { isOpen: { $set: false } },
                [uncheckedPrompts]: { isOpen: { $set: true } },
              });
              setPrompts(newPromptsData);
            } else {
              setLoading(true);
              setShowPrompts(false);
              await addToCart(promptTempMembers);
              setLoading(false);
            }
          }}
          prompts={prompts}
        />
      )}

      <WarningAlert
        isOpen={notEnoughTimeSlots}
        title="Sorry"
        handleClick={async () => {
          await fetchTimeSlots(
            selectedTimeSlots.map(time => {
              return {
                selectedScheduledDate: time.scheduledTime,
                ...(time.trackNo && { trackNo: time.trackNo }),
              };
            }),
            comboProductsList?.[activeProductIndex].productID,
          );
          setSelectedTimeSlots([]);
          setShowListOfGuests(false);
          dispatch(updateStatusNotEnoughTimeSlots({ notEnoughTimeSlots: false }));
          dispatch(hideSelectionOfParticipant());
        }}
        description="It looks like there are no longer enough timeslots for the number of guests you’ve selected. Please pick another product or another timeslot."
      />
      <Confirm
        isOpen={showGuestsLimitationWarning}
        title="Sorry"
        titleButtonOk="continue"
        titleButtonCancel=""
        handleContinueClick={() => {
          setShowGuestsLimitationWarning(false);
        }}
      >
        <div className={styles.guestLimitationsWarningModalWrapper}>
          <p>
            It looks like you've chosen an incorrect number of participants. Please update your selection to continue.
          </p>
          <div>
            <p>Minimum Guests: {currentCombo?.minimumGuests}</p>
            <p>Maximum Guests: {currentCombo?.maximumGuests}</p>
          </div>
        </div>
      </Confirm>
      {membershipStore.customers && !cartLoading && (
        <ProductMembershipRequired
          comboGroupId={comboGroupId}
          hideBackButton={false}
          hideCancelButton={false}
          handleBackButton={() => {
            // if (!membershipStore?.product?.isSchedulable) {
            //   cancelCombos();
            // } else {
            cancelCombos();
            setActiveProductIndex(0);
            setSelectedMembers([]);
            setShowListOfGuests(true);
            // }
          }}
          closeChooseParticipant={() => {
            if (!membershipStore?.product?.isSchedulable) {
              cancelCombos();
              history.replace(`${ROUTES.PRODUCTS}/${comboCategory.id}`, {
                title: comboCategory.id,
              });
            } else {
              setShowListOfGuests(false);
              dispatch(hideSelectionOfParticipant());
            }
          }}
          productDetails={comboProductsList?.[activeProductIndex]}
          chooseParticipant={() => {
            setShowListOfGuests(true);
            dispatch(
              showSelectionOfParticipant({
                checkId,
                productId: productDetail.productId,
                scheduledTime: selectedTimeSlots.map(time => time.scheduledTime),
              }),
            );
          }}
          areOnlyNonschedulableProducts={() => {
            const nonschedulableProducts = comboProductsList.filter(product => !product.isSchedulable);
            return nonschedulableProducts.length === comboProductsList.length;
          }}
        />
      )}
      <Confirm
        isOpen={showDialogModal}
        title="Are you sure you want to Cancel?"
        titleButtonCancel="No"
        titleButtonOk="Yes"
        handleReturnClick={() => setShowDialogModal(false)}
        handleContinueClick={() => {
          cancelCombos();

          history.replace(`${ROUTES.PRODUCTS}/${comboCategory.id}`, {
            title: comboCategory.id,
          });
        }}
      >
        <div className={styles.cancelCombosModal}>
          <p>Combos will be deleted from the cart</p>
        </div>
      </Confirm>
      <IdleMonitor />
    </>
  );
};

export default ComboDetailsScreen;
