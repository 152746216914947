import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

interface TimeSlotsListItemProps {
  data: DataTyping;
  handleClick?: Function;
  isActive: boolean;
  isSelected?: boolean;
  trackNo?: number;
}

interface DataTyping {
  timeSlotStatus: number;
  timeSlotStatusDescription: string;
  scheduledTime: string;
  slotsRemaining: number;
  activityId: number;
}

const TimeSlotGridItem = ({ trackNo, data, handleClick, isActive, isSelected }: TimeSlotsListItemProps) => {
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  return (
    <div
      onClick={() => handleClick({ trackNo, item: data })}
      className={cn(styles.timeSlotsListItem, {
        [styles.memberCardActive]: isActive,
        [styles.memberCardSelected]: isSelected,
        [styles.timeSlotsListItemNoSlotsLeft]: data.slotsRemaining <= 0 || data.timeSlotStatus !== 0,
      })}
      style={{ background: isSelected && primaryColor }}
    >
      <div className={styles.timeSlotsListItemInner}>
        <div className={styles.timeSlotsListItemTime}>{moment(data.scheduledTime).format('hh:mm A')}</div>
        <div className={styles.timeSlotsListItemLot}>
          {(isSelected || (data.timeSlotStatus === 0 && data.slotsRemaining > 0)) && <>{data.slotsRemaining} left</>}
          {data.timeSlotStatus === 0 && data.slotsRemaining <= 0 && <>Sold Out</>}
          {data.timeSlotStatus !== 0 && !isSelected && <>{data.timeSlotStatusDescription}</>}
        </div>
      </div>
    </div>
  );
};

TimeSlotGridItem.defaultProps = {
  handleClick: () => {},
  isSelected: false,
};

export default TimeSlotGridItem;
