import { generateImage } from 'utils/core';
import { GET_KIOSK_SETTINGS_TYPES } from '../actions';

export const initialState = {
  branding: {
    logo: null,
    backgroundImg: null,
    welcomeText: '',
    primaryColor: '#4D2ED9',
    backgroundImgType: 0,
  },
  loading: false,
};

export type InitialStateType = typeof initialState;

export default function globalSettingsReducer(state = initialState, { type, payload = {} }: any): InitialStateType {
  switch (type) {
    case GET_KIOSK_SETTINGS_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_KIOSK_SETTINGS_TYPES.SUCCESS:
      return {
        branding: {
          ...payload,
          backgroundImg: generateImage(payload.backgroundImg),
          logo: generateImage(payload.logo),
        },
        loading: false,
      };
    case GET_KIOSK_SETTINGS_TYPES.FAILURE:
      return {
        ...initialState,
        loading: false,
      };
    default:
      return state;
  }
}
