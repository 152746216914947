import React from 'react';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { terminalSettingsSelector } from '../../store/Settings/selectors';
import { endCustomerSessionAction } from '../../store/General/actions';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import CountdownTimer from '../../components/CountdownTimer';
import styles from './styles.module.scss';
import { deleteCartAction } from '../../store/Cart/actions';

const IdleMonitor = ({ customTimeout, removeLineItems = true }: any) => {
  const terminalSettings = useSelector(terminalSettingsSelector);
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  const timeout = customTimeout ? Number(customTimeout) : Number(terminalSettings.sessionTimeout);
  const [modal, setModal] = React.useState(false);
  const onIdleChange = (e: any) => {
    setModal(true);
  };
  const onIdleAction = (e: any) => {};

  const continueHandler = () => {
    setModal(false);
  };

  const dispatch = useDispatch();

  const endCustomerSession = async () => {
    setModal(false);
    dispatch(
      deleteCartAction({
        removeLineItems,
      }),
    );
    dispatch(endCustomerSessionAction());
  };
  return (
    <>
      <IdleTimer onAction={onIdleAction} onIdle={onIdleChange} debounce={250} timeout={timeout * 60000 - 60000} />
      <Modal isOpen={modal} zIndex={500}>
        <div className={styles.idleMonitor}>
          <div className={styles.content}>
            <h2 className={styles.title}>Session Expiration</h2>
            <h3 className={styles.subTitle}>
              Because you have been inactive, you session
              <br /> is about to expire
            </h3>
            <div className={styles.countdown}>
              <CountdownTimer time={60} onComplete={endCustomerSession} />
            </div>
          </div>
          <div className={styles.footer}>
            <Button handleClick={endCustomerSession} customClass={styles.button} theme="grey">
              Log out
            </Button>
            <Button handleClick={continueHandler} customClass={styles.button} theme="red">
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IdleMonitor;
