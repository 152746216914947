// import React, { Fragment, useState } from 'react';
// import cn from 'classnames';
// import { useDispatch, useSelector } from 'react-redux';
// import {terminalModeStateSelector} from '../../store/Settings/selectors'
// import { endCustomerSessionAction } from '../../store/General/actions';
// import { ROUTES } from '../../constants';
// import { history } from '../../App';
// import Logo from '../Logo';
// import ButtonIcon from '../ButtonIcon';
// import HomeIcon from '../../assets/icons/home.svg';
// import LogoutIcon from '../../assets/icons/logout.svg';
// import styles from './styles.module.scss';
// import Confirm from '../Prompt';
// import {isDefaultMode, isFoodAndActivitiesMode} from "../../utils/detectTerminalMode";
//
// interface HeaderPanelProps {
//   homeButton?: boolean;
//   backButton?: boolean;
//   startOverButton?: boolean;
//   logOutButton?: boolean;
//   paginationHistory?: string;
//   paginationStep?: string;
//   shadow?: boolean;
//   border?: boolean;
//   handleHomeClick?: () => void;
//   handleBackClick?: () => void;
// }
//
// const HeaderPanel: React.FC<HeaderPanelProps> = ({
//   backButton,
//   homeButton,
//   startOverButton,
//   logOutButton,
//   paginationHistory,
//   paginationStep,
//   shadow,
//   border,
//   handleHomeClick,
//   handleBackClick,
// }: HeaderPanelProps) => {
//   const dispatch = useDispatch();
//   const [confirmLogOutModal, setConfirmLogOutModal] = useState(false);
//   // @ts-ignore
//   const { cartStore: { checkDetails }, terminal: { customerSessionMode } } = useSelector(state => state);
//   const signOut = () => {
//     if (checkDetails.length) {
//       setConfirmLogOutModal(true);
//       return;
//     }
//     dispatch(endCustomerSessionAction());
//   };
//
//   const cancelLogOut = () => {
//     setConfirmLogOutModal(false);
//   };
//
//   const logOut = () => {
//     setConfirmLogOutModal(false);
//     dispatch(endCustomerSessionAction());
//   };
//
//   const onHomeClick = () => {
//     if (handleHomeClick) {
//       return handleHomeClick();
//     }
//     history.replace(ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE);
//   };
//
//   return (
//     <Fragment>
//       <div
//         id="headerPanel"
//         className={styles.headerContainer}
//         style={{
//           boxShadow: shadow ? '0px 10px 40px rgba(0, 0, 0, 0.15)' : 'none',
//           borderBottom: border ? '1px solid #D9D9D9' : 'none',
//         }}
//       >
//         <div className={styles.leftContainer}>
//           {!!(homeButton && ( isDefaultMode(customerSessionMode) || isFoodAndActivitiesMode(customerSessionMode) )) && (
//             <div className={styles.mr20}>
//               <ButtonIcon icon={HomeIcon} onClick={onHomeClick}>
//                 Home
//               </ButtonIcon>
//             </div>
//           )}
//           {paginationHistory && paginationStep && (
//             <div className={cn(styles.pagination, styles.mr20)}>
//               <span>{paginationHistory}</span> / <span className={styles.paginationStep}>{paginationStep}</span>
//             </div>
//           )}
//           {backButton && handleBackClick && (
//             <div className={styles.mr20}>
//               <ButtonIcon onClick={handleBackClick}>Back</ButtonIcon>
//             </div>
//           )}
//         </div>
//         <div className={styles.centerContainer}>
//           <Logo theme="dark" />
//         </div>
//         <div className={styles.rightContainer}>
//           {startOverButton && (
//             <div className={styles.mr20}>
//               <ButtonIcon icon={LogoutIcon} onClick={signOut}>
//                 Start over
//               </ButtonIcon>
//             </div>
//           )}
//           {logOutButton && (
//             <div className={styles.mr20}>
//               <ButtonIcon icon={LogoutIcon} onClick={signOut}>
//                 Log Out
//               </ButtonIcon>
//             </div>
//           )}
//         </div>
//       </div>
//       <Confirm
//         isOpen={confirmLogOutModal}
//         title="Are you sure you want to log out?"
//         titleButtonCancel="Cancel"
//         titleButtonOk="Continue"
//         handleReturnClick={() => cancelLogOut()}
//         handleContinueClick={() => logOut()}
//       >
//         <div className={styles.confirmLogOutModal}>
//           <p>All you purchase information will be deleted.</p>
//         </div>
//       </Confirm>
//     </Fragment>
//   );
// };
//
// HeaderPanel.defaultProps = {
//   homeButton: false,
//   startOverButton: false,
//   logOutButton: false,
//   paginationHistory: '',
//   paginationStep: '',
//   shadow: false,
//   border: false,
// };
// export default HeaderPanel;

import React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { endCustomerSessionAction } from '../../store/General/actions';
import { terminalModeStateSelector } from '../../store/Settings/selectors';
import Logo from '../Logo';
import ButtonIcon from '../ButtonIcon';
import { isCheckInMode, isDefaultMode, isFoodAndActivitiesMode } from '../../utils/detectTerminalMode';
import LogoutIconWhite from '../../assets/icons/logout-white.svg';
import LogoutIconBlack from '../../assets/icons/logout.svg';
import HomeIconBlack from '../../assets/icons/home.svg';
import HomeIconWhite from '../../assets/icons/home-white.svg';
import styles from './styles.module.scss';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import Confirm from '../Prompt';
import { deleteCartAction } from '../../store/Cart/actions';

interface HeaderPanelWhiteProps {
  homeButton?: boolean;
  startOverButton?: boolean;
  logOutButton?: boolean;
  handleHomeClick?: () => void;
  border?: boolean;
  paginationHistory?: string;
  paginationStep?: string;
  shadow?: boolean;
  backButton?: boolean;
  handleBackClick?: () => void;
  theme?: string;
}

const HeaderPanelWhite = ({
  homeButton,
  startOverButton,
  logOutButton,
  handleHomeClick,
  border,
  paginationHistory,
  paginationStep,
  shadow,
  backButton,
  handleBackClick,
  theme,
}: HeaderPanelWhiteProps) => {
  const dispatch = useDispatch();
  const [confirmLogOutModal, setConfirmLogOutModal] = React.useState(false);
  // @ts-ignore
  const {
    cartStore: { checkDetails, comboCheckDetails, comboCheckDetailsPerResource },
    terminalSettings: { mode },
  } = useSelector(state => state);

  const onHomeClick = () => {
    if (handleHomeClick) {
      return handleHomeClick();
    }
    history.replace(ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE);
  };

  const signOut = () => {
    if (checkDetails.length || comboCheckDetails.length || comboCheckDetailsPerResource.length) {
      setConfirmLogOutModal(true);
      return;
    }
    dispatch(endCustomerSessionAction());
  };

  const cancelLogOut = () => {
    setConfirmLogOutModal(false);
  };

  const logOut = async () => {
    setConfirmLogOutModal(false);
    dispatch(
      deleteCartAction({
        removeLineItems: true,
      }),
    );
    dispatch(endCustomerSessionAction());
  };

  // const goHome = () => {
  //   history.replace(ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE);
  // };

  return (
    <>
      <div
        className={cn(styles.mainMenu, styles[`mainMenu${theme}`], {
          [styles.mainMenuBorder]: border,
          [styles.mainMenuShadow]: shadow,
        })}
        id="headerPanel"
      >
        <div className={styles.leftContainer}>
          {!!(homeButton && (isDefaultMode(mode) || isFoodAndActivitiesMode(mode))) && (
            <ButtonIcon
              icon={theme === 'dark' ? HomeIconWhite : HomeIconBlack}
              color={theme === 'dark' ? '#fff' : '#000'}
              onClick={onHomeClick}
            >
              Home
            </ButtonIcon>
          )}
          {paginationHistory && paginationStep && (
            <div className={cn(styles.pagination)}>
              <span>{paginationHistory}</span> / <span className={styles.paginationStep}>{paginationStep}</span>
            </div>
          )}
          {backButton && handleBackClick && <ButtonIcon onClick={handleBackClick}>Back</ButtonIcon>}
        </div>
        <div className={styles.centerContainer}>
          <Logo theme={theme === 'dark' ? 'light' : 'dark'} width="200" height="60" />
        </div>
        <div className={styles.rightContainer}>
          {(startOverButton || logOutButton) && (
            <ButtonIcon
              icon={theme === 'dark' ? LogoutIconWhite : LogoutIconBlack}
              color={theme === 'dark' ? '#fff' : '#000'}
              onClick={signOut}
            >
              {startOverButton ? 'Start over' : 'Log Out'}
            </ButtonIcon>
          )}
        </div>
      </div>

      <Confirm
        isOpen={confirmLogOutModal}
        title="Are you sure you want to log out?"
        titleButtonCancel="Cancel"
        titleButtonOk="Continue"
        handleReturnClick={() => cancelLogOut()}
        handleContinueClick={() => logOut()}
      >
        <div className={styles.confirmLogOutModal}>
          <p>All you purchase information will be deleted.</p>
        </div>
      </Confirm>
    </>
  );
};

HeaderPanelWhite.defaultProps = {
  homeButton: false,
  startOverButton: false,
  logOutButton: false,
};
export default HeaderPanelWhite;
