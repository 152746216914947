import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import IdleMonitor from '../../services/IdleMonitor';
import Button from '../../components/Button';
import cn from 'classnames';
import { history } from '../../App';
import { terminalModeStateSelector } from '../../store/Settings/selectors';
import { ReactComponent as EventsIcon } from '../../assets/icons/events-icon.svg';
import { startCustomerSessionAction } from '../../store/Terminal/actions';
import EventListItem from '../../components/EventListItem';
import { getCurrentDayEvents } from '../../store/Customer/actions';
import { selectEvent } from '../../store/Customer/actions/eventsActions';
import { ROUTES } from '../../constants';
import Icon from 'components/Icon';

const EventsListScreen = () => {
  const dispatch = useDispatch();
  const currentDayEvents = useSelector(state => state.events.data);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [step, setStep] = useState(1);
  const terminalMode = useSelector(terminalModeStateSelector);

  useEffect(() => {
    dispatch(getCurrentDayEvents());
  }, []);

  const handleContinue = () => {
    dispatch(selectEvent(selectedEvent));
    history.push(ROUTES.LOG_IN_FIRST_STEP);
  };

  return (
    <>
      <ContentContainer>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />
        <div className={styles.wizardFormField}>
          <div className={styles.wizardFormFieldInner}>
            {step === 1 ? (
              <>
                <div className={styles.confirmation}>
                  <h1>Are You Here for Event?</h1>
                  <div className={styles.imageWrapper}>
                    <Icon ImgComponent={EventsIcon} />
                  </div>
                  <p>Select yes and you'll be able to choose from the list of events for today</p>
                  <div>
                    <Button
                      customClass={styles.btn}
                      handleClick={() => dispatch(startCustomerSessionAction(terminalMode))}
                    >
                      NO
                    </Button>
                    <Button theme="red" customClass={styles.btn} handleClick={() => setStep(2)}>
                      YES
                    </Button>
                  </div>
                </div>
                <div className={styles.navigation}>
                  <Button
                    theme={'white'}
                    handleClick={() => history.goBack()}
                    customClass={cn(styles.navigationButton, styles.navigationButtonBack)}
                  >
                    Back
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className={styles.eventContentWrapper}>
                  <div className={styles.rowMargin}>
                    <h1>Select Your Event and Complete Registration</h1>
                  </div>
                  {currentDayEvents?.length ? (
                    <div className={styles.eventListWrapper}>
                      <div>
                        {currentDayEvents?.map(event => {
                          return (
                            <EventListItem
                              key={event.eventID}
                              text={`${event.eventTypeName} (${event.eventDesc})`}
                              onClick={() => setSelectedEvent(event)}
                              selected={event.eventID === selectedEvent?.eventID}
                            />
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.noEventListWrapper}>
                      <h2>There Aren't Any Events Scheduled For Today</h2>
                    </div>
                  )}
                </div>
                <div className={styles.navigation}>
                  <Button
                    theme={'white'}
                    handleClick={() => setStep(1)}
                    customClass={cn(styles.navigationButton, styles.navigationButtonBack)}
                  >
                    Back
                  </Button>
                  <div className={styles.navigationFilter} />
                  {selectedEvent && (
                    <Button
                      theme="red"
                      handleClick={() => handleContinue()}
                      customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
                    >
                      continue
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <IdleMonitor />
      </ContentContainer>
    </>
  );
};

export default EventsListScreen;
