import React from 'react';
import LogoLight from '../../assets/images/logo.svg';
import LogoDark from '../../assets/images/logo-dark.svg';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

interface LogoProps {
  width?: string;
  height?: string;
  theme?: string;
}

const Logo = ({ theme = 'light', width = 'auto', height = 'auto' }: LogoProps) => {
  const { logo } = useSelector(state => state.kioskSettings.branding);

  if (logo) {
    return <img src={logo} className={styles.logo} width={width} height={height} alt="" />;
  }

  return <img src={theme === 'light' ? LogoLight : LogoDark} className={styles.logo} width={width} alt="" />;
};

export default Logo;
