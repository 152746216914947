import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface ContentContainerProps {
  children: React.ReactNode;
  loading?: boolean;
  theme?: string;
  bgColor?: string;
  customImg?: string | null;
  backgroundImgType?: number;
}

const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  loading = false,
  theme = 'default',
  bgColor = '#F2F2F2',
  customImg,
  backgroundImgType = 0,
}: ContentContainerProps) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().then(() => {
        // Video started playing
      });

      videoRef.current.onended = () => {
        // Video has ended, restart it
        videoRef.current.play();
      };
    }
  }, []);

  console.log(backgroundImgType, 'backgroundImgType');
  console.log(customImg, 'customImg');

  return (
    <>
      {backgroundImgType === 1 && (
        <video
          ref={videoRef}
          autoPlay
          muted
          loop
          playsInline
          style={{
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        >
          <source src={customImg} type="video/mp4" />
        </video>
      )}
      <div
        className={cn(styles.container, !customImg && styles[`${theme}Theme`])}
        style={{
          backgroundColor: backgroundImgType === 1 ? 'transparent' : bgColor,
          ...(customImg && backgroundImgType === 0 && { backgroundImage: `url(${customImg})` }),
          ...(backgroundImgType === 1 && { position: 'absolute', zIndex: 22, width: '100%', height: '100%' }),
          backgroundSize: 'cover',
        }}
      >
        {children}
        {loading && (
          <div className={styles.loaderWrapper}>
            <div className={styles.loader} />
          </div>
        )}
      </div>
    </>
  );
};

export default ContentContainer;
