import React from 'react';
import { useSelector } from 'react-redux';
import Icon from 'components/Icon';
import styles from './styles.module.scss';
import { ReactComponent as Checked } from '../../assets/icons/tick.svg';
import cn from 'classnames';

interface RadioProps {
  state: boolean;
  label: string;
  handleClick: any;
}

const Radio: React.FC<RadioProps> = ({ state, label, handleClick }: RadioProps) => {
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  return (
    <div className={styles.checkboxWrap}>
      <label className={cn(styles.checkboxLabel, primaryColor && state && styles.checkBoxWrapper)}>
        {state && primaryColor ? (
          <div
            style={{
              background: primaryColor,
              borderRadius: '20px',
              width: '38px',
              height: '38px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '1.75em',
            }}
            onClick={() => handleClick()}
          >
            <Icon ImgComponent={Checked} />
          </div>
        ) : (
          <>
            <input type="radio" readOnly onClick={handleClick} checked={state} className={styles.checkboxInput} />
            <span className={styles.checkmark} />
          </>
        )}
        {label}
      </label>
    </div>
  );
};

Radio.defaultProps = {
  state: false,
};

export default Radio;
