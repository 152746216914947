import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import * as Api from '../../api';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import { saveCustomerToStoreAction } from '../../store/Customer/actions';
import IdleMonitor from '../../services/IdleMonitor';
import ContentContainer from '../../components/ContentContainer';
import HeaderPanel from '../../components/HeaderPanel';
import styles from './styles.module.scss';
import isAdult from '../../utils/isAdult';
import Button from '../../components/Button';
import ReactSignatureCanvas from 'react-signature-canvas';
import { addSignatureOfNewGuestAction } from '../../store/Customer/actions';
import Checkbox from '../../components/Checkbox';
import WaiverAgreementText from '../../components/WaiverAgreementText';

const EnforceWaiverAgreementAdultScreen = () => {
  // @ts-ignore
  const { id } = useParams();
  const dispatch = useDispatch();
  const sigPad = React.useRef(null);

  const {
    newCustomerStore,
    newGuestStore,
    registrationSettings: { adultAge, waiverStep },
  } = useSelector(state => state);
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  const [currentAdultNewGuest, setCurrentAdultNewGuest] = useState(null);
  const [agreeWithRules, setAgreeWithRules] = useState(false);
  const [agreeWithRulesError, setAgreeWithRulesError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentAdultNewGuest(_.find(newGuestStore.guests, obj => obj.id === id));
  }, [id]);

  const startRegistrationFlow = async listOfNewGuests => {
    try {
      setLoading(true);
      const listOfNewMinorGuests = listOfNewGuests.filter(item => !isAdult(item.commonData.birthDate, adultAge));
      const listOfNewAdultGuests = listOfNewGuests.filter(
        item => isAdult(item.commonData.birthDate, adultAge) && item.signature,
      );
      const { data: primaryCust } = await Api.createCustomer(newCustomerStore.commonData);

      await Api.createAdultWaiver({
        signature: newCustomerStore.signature,
        adultCustId: primaryCust.custId,
      });

      for (const item of listOfNewMinorGuests) {
        const {
          data: { custId: curentCustId },
        } = await Api.createCustomer(item.commonData);
        await Api.createMinorWaiver({
          guardianSignature: newCustomerStore.signature,
          minorCustId: curentCustId,
          guardianCustId: primaryCust.custId,
        });
        await Api.addToCustomerGroup({
          custIds: [curentCustId],
          createdById: primaryCust.custId,
        });
        await Api.addToCustomerRelationships({
          relationships: [
            {
              relationship: item.relationships,
              custId: curentCustId,
            },
          ],
          custId: primaryCust.custId,
        });
      }
      for (const item of listOfNewAdultGuests) {
        const {
          data: { custId: curentCustId },
        } = await Api.createCustomer(item.commonData);
        await Api.createAdultWaiver({
          signature: item.signature,
          adultCustId: curentCustId,
        });
        await Api.addToCustomerGroup({
          custIds: [curentCustId],
          createdById: primaryCust.custId,
        });
        await Api.addToCustomerRelationships({
          relationships: [
            {
              relationship: item.relationships,
              custId: curentCustId,
            },
          ],
          custId: primaryCust.custId,
        });
      }
      setLoading(false);
      dispatch(saveCustomerToStoreAction(primaryCust));
    } catch (e) {
      setLoading(false);
    }
  };

  const onBackClick = () => {
    history.goBack();
  };

  const onContinueClick = async () => {
    try {
      const listOfNewGuests = newGuestStore.guests;
      const indexOfCurrentNewGuest = listOfNewGuests.findIndex(item => item.id === id);

      const signatureOfCustomer = sigPad.current.toDataURL().substr(22);
      if (!agreeWithRules) {
        setAgreeWithRulesError(true);
        return;
      }
      setAgreeWithRulesError(false);
      if (sigPad.current.isEmpty()) {
        return;
      }
      listOfNewGuests[indexOfCurrentNewGuest].signature = signatureOfCustomer;

      const listOfNewAdultGuestsWithoutSignature = listOfNewGuests.filter(
        item => isAdult(item.commonData.birthDate, adultAge) && !item.signature,
      );

      if (listOfNewAdultGuestsWithoutSignature.length > 0) {
        dispatch(addSignatureOfNewGuestAction(listOfNewGuests));
        sigPad.current.clear();
        history.push(`${ROUTES.WAIVER_AGREEMENT_ADULT}/${listOfNewAdultGuestsWithoutSignature[0].id}`);
        return;
      }
      await startRegistrationFlow(listOfNewGuests);
      history.push(ROUTES.MAIN_FOOD_AND_ACTIVITIES_MODE);
    } catch (e) {}
  };

  return (
    <Fragment>
      <ContentContainer loading={loading}>
        <HeaderPanel startOverButton shadow paginationHistory="" paginationStep={''} />
        <div className={styles.content}>
          <div className={styles.waiverAgreementTextContainer}>
            <span className={styles.waiverAgreementTextTitle}>
              Waiver Agreement - {currentAdultNewGuest ? currentAdultNewGuest.commonData.fName : ''}{' '}
              {currentAdultNewGuest ? currentAdultNewGuest.commonData.lName : ''}
            </span>
            <WaiverAgreementText waiverText={waiverStep.adultWaiverText} />
          </div>
          <div className={styles.waiverAgreement}>
            <div>
              <Checkbox
                state={!!agreeWithRules}
                label="I agree to the terms and conditions of the above agreement."
                handleClick={() => setAgreeWithRules(prevState => !prevState)}
                error={agreeWithRulesError}
              />
            </div>
          </div>
          <div className={styles.waiverAgreementSignatureContainer}>
            <span className={styles.waiverAgreementSignatureTitle}>
              Waiver Signature - {currentAdultNewGuest ? currentAdultNewGuest.commonData.fName : ''}{' '}
              {currentAdultNewGuest ? currentAdultNewGuest.commonData.lName : ''}
            </span>
            <div className={styles.waiverAgreementSignatureCanvas}>
              <div className={styles.waiverAgreementSignatureCanvasInner}>
                <ReactSignatureCanvas canvasProps={{ width: 550, height: 250 }} backgroundColor="white" ref={sigPad} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.navigation}>
          <Button theme="white" size="base" handleClick={onBackClick} customClass={styles.whiteButton}>
            Back
          </Button>
          <div className={styles.navigationFilter} />
          <Button theme="red" size="base" handleClick={onContinueClick} customClass={styles.redButton}>
            Continue
          </Button>
        </div>
      </ContentContainer>
      <IdleMonitor />
    </Fragment>
  );
};

export default EnforceWaiverAgreementAdultScreen;
