import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import styles from './styles.module.scss';

interface SelectableCardProps {
  text?: string;
  value?: string;
  handleClick?: () => void;
  removeHandler?: () => void;
  isActive?: boolean;
}

const SelectableCard: React.FC<SelectableCardProps> = ({
  text,
  value,
  handleClick,
  removeHandler,
  isActive,
}: SelectableCardProps) => {
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  return (
    <div className={cn(styles.memberCard, { [styles.memberCardActive]: isActive && !primaryColor })}>
      <button
        className={styles.memberCardInner}
        onClick={handleClick}
        style={{
          borderColor: isActive && primaryColor ? primaryColor : '',
        }}
      >
        <div className={styles.memberCardAvatar}>
          <h3 className={styles.memberCardName}>{text}</h3>
        </div>
      </button>
    </div>
  );
};

SelectableCard.defaultProps = {
  text: '',
  value: '',
  handleClick: () => {},
  removeHandler: () => {},
  isActive: false,
};

export default SelectableCard;
