import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

interface Props {
  value: number;
  handleClick: (type: string) => void;
}

const InputNumber = ({ value, handleClick }: Props) => {
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  return (
    <div className={styles.inputNumber}>
      <button
        onClick={() => handleClick('minus')}
        className={styles.minus}
        style={{ color: primaryColor ? primaryColor : '' }}
      >
        -
      </button>
      <input type="text" readOnly value={value} />
      <button
        onClick={() => handleClick('plus')}
        className={styles.plus}
        style={{ background: primaryColor ? primaryColor : '' }}
      >
        +
      </button>
    </div>
  );
};

export default InputNumber;
