import React from 'react';
import { useSelector } from 'react-redux';

interface IProps {
  ImgComponent: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
}

const Icon = ({ ImgComponent }: IProps) => {
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  return (
    <ImgComponent
      style={{
        fill: primaryColor || '#df151c',
      }}
    />
  );
};

export default Icon;
