import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import Icon from 'components/Icon';
import { ReactComponent as Checked } from '../../assets/icons/tick.svg';

interface CheckboxProps {
  state: boolean;
  disabled?: boolean;
  label: string;
  handleClick: any;
  error?: boolean;
  theme?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  state,
  disabled,
  label,
  handleClick,
  error = false,
  theme = 'red',
}: CheckboxProps) => {
  const { primaryColor } = useSelector(state => state.kioskSettings.branding);

  return (
    <div className={cn(styles.checkboxContainer, styles[`${theme}`])}>
      <button
        type="button"
        disabled={disabled}
        onClick={handleClick}
        className={cn(styles.checkmark, { [styles.checked]: state && !primaryColor })}
        style={{
          ...(state && primaryColor
            ? {
                background: primaryColor,
                border: `1px solid ${primaryColor}`,
              }
            : {}),
        }}
      >
        {state && primaryColor && <Icon ImgComponent={Checked} />}
      </button>
      <div className={cn(styles.label, { [styles.error]: error })}>{label}</div>
    </div>
  );
};

export default Checkbox;
